import {
    RECEIVE_WORKSPACES, RECEIVE_WORKSPACE, ADD_WORKSPACE, DELETE_WORKSPACE,
    DELETE_WIDGET, SET_WIDGET_STATUS
} from '../types/actionTypes.workspace';
import _ from 'lodash';

const ensureStructur = (obj) => {
    // ensure workspaces/widgets stored in redux have the correct structure - e.g. add empty objects to ensure we dont access undefined variables somewhere
    obj = !obj.state ? {
        ...obj,
        state: {
            settings: {}
        }
    } : obj;
    if (!obj.state.settings) obj.state.settings = {};
    return obj;
}

const workspaceReducers = (state = [], action) => {
    let newState;

    switch (action.type) {
        case RECEIVE_WORKSPACES:
            return action.data.map(workspace => {
                let oldWorkspace = _.find(state, { id: workspace.id });
                let widgets = [];
                workspace.widgets.map((widget) => {
                    if (widget !== undefined) {
                        if (oldWorkspace) {
                            const oldWidget = _.find(oldWorkspace.widgets, { dbId: widget.dbId });
                            if (oldWidget) {
                                widget._local = oldWidget._local;
                            }
                        }
                        widgets.push(ensureStructur(widget));
                    }
                    return undefined;
                });
                workspace.widgets = widgets;
                return ensureStructur(workspace);
            });
        case RECEIVE_WORKSPACE:
            let workspaceIndex = _.findIndex(state, { id: action.data.id });
            newState = _.cloneDeep(state);
            if (workspaceIndex !== -1) {
                let receivedWorkspace = _.cloneDeep(action.data);
                let widgets = [];
                receivedWorkspace.widgets = receivedWorkspace.widgets.map(w => {
                    if(w !== undefined){
                        widgets.push(ensureStructur(w));
                    }
                    return undefined;
                });
                receivedWorkspace.widgets = widgets;
                newState[workspaceIndex] = ensureStructur(receivedWorkspace);
            }
            return newState;
        case ADD_WORKSPACE:
            let newWorkspace = _.cloneDeep(action.data);
            newWorkspace.widgets = newWorkspace.widgets.map(w => ensureStructur(w));
            return [...state, ensureStructur(newWorkspace)];
        case DELETE_WORKSPACE:
            newState = [...state];
            workspaceIndex = _.findIndex(state, { id: action.data.id });
            newState.splice(workspaceIndex, 1);
            return newState;
        // case RECEIVE_WIDGET:
        //     newState = _.cloneDeep(state);
        //     console.log(action.data);
        //     return newState;
        case DELETE_WIDGET:
            let widgetIndex;
            for (let i = 0; i < state.length; i++){
                widgetIndex = _.findIndex(state[i].widgets, { dbId: action.data.widgetId });
                if (widgetIndex !== -1) {
                    state[i].widgets.splice(widgetIndex, 1);
                    break;
                }
            }
            return state;
        case SET_WIDGET_STATUS:
            newState = _.cloneDeep(state);
            const workspaceIdx = _.findIndex(newState,{ id: action.data.workspaceId })
            const widgetIdx = _.findIndex(newState[workspaceIdx].widgets, { id: action.data.widgetId });
            if (widgetIdx !== undefined) {
                newState[workspaceIdx].widgets[widgetIdx]._local.status = action.data.status;
            }
            return newState;
        default:
            return state;
    }
};

export default workspaceReducers;