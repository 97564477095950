import config from 'src/config/config';
import GenericCalls from './genericCalls';

class APIChannels {
    static async get_legacy(){
        let rv = await GenericCalls.get("/cgi-bin/feed/channels");
        const props = rv.shift();
        rv = rv.map((channel) => (
            channel.reduce((res,val,i)=>{ res[props[i]] = val; return res; },{})
        ));
        return rv;
    }

    static async get(id) {
        let url = "/api/channels";
        if (id) url += "/" + id;
        // if (start !== undefined && limit !== undefined) {
        //     url += "?start=" + start + "&limit=" + limit;
        // }
        // if (fields) {
        //     // url += start !== undefined ? "&" : "?";
        //     url += "?fields=" + fields.join(",");
        // }

        // let tmpMockChannels = ()=>{
        //     let rv = [];
        //     for (let i = 0; i < 4; i++) {            
        //         let tmp = { id: (i + 1), city: "vienna", country: "at", icon: "someIcon", language: "de", market: "de", owner: "private", schedule: "24x7", scope: "regional", name: "testChannel", type: "Web Text" };           
        //         rv.push(tmp);
        //     }
        //     return rv;
        // }

        let response = await GenericCalls.get(url);        
        
        return response; //.concat(tmpMockChannels());
    }

    static async search(searchTerm, start, limit) {
        let url = "/api/channels/search/" + searchTerm;
        if (start && limit) url += "?start=" + start + "&limit=" + limit;

        return GenericCalls.get(url);
    }
}

export default APIChannels;
