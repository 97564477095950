import React, { useEffect, useState } from 'react';
import styles from './ClipAnalytics.module.scss';
import { useAppSelector } from 'src/redux/hooks';
import { getAvailableWidgets } from 'src/Board/Workspace/availableWidgets';
import Button from 'src/Widgets/common/basicElements/Button/Button';


import geoBreakdown from 'src/assets/geoBreakdown.png';
import sentimentBreakdown from 'src/assets/sentimentBreakdown.png';
import scopeOfMentionsRel from 'src/assets/scopeOfMentions_relative.png';
import mentionsPerChannel from 'src/assets/mentionsPerChannel_absolute.png';
import languageBreakdown from 'src/assets/languageBreakdown.png';
import scopeBreakdown from 'src/assets/scopeBreakdown.png';
import mediaTypeBreakdown from 'src/assets/mediaTypeBreakDown.png';
import mentionsTable from 'src/assets/mentionsTable.png';


import { useTranslation } from 'react-i18next';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import useGenerateClipsReport from '../../hooks/useGenerateClipsReport';

const ClipAnalytics = ({
    selectedDateRange,
    profileGroupContent,
    tooltipVisible,
    setTooltipVisible,
    activeFilter,
    isEdited,
    isSearch
}: MentionsVisualizerTypes.ClipAnalyticsProps) => {

    const isMounted = React.useRef(false);

    const [availableAnalyticsWidgets, setAvailableAnalyticsWidgets] = useState<any>([]);
    const [selectedAnalyticsWidgets, setSelectedAnalyticsWidgets] = useState<any>([
        {
            key: 'reportTitlePage',
            name: 'Report Title Page'
        }
    ]);

    const{handleGenerateReport, generatingReport} = useGenerateClipsReport({
        selectedDateRange,
        profileGroupContent,
        activeFilter,
        isEdited,
        isSearch,
        selectedAnalyticsWidgets
    });

    const me = useAppSelector((state) => state.me);
    

    const { t } = useTranslation();

    useEffect(() => {
        isMounted.current = true;
        const widgets = getAvailableWidgets(
            me?.experimentalWidgetsSelectable,
            me?.allWidgets
        );
        setAvailableAnalyticsWidgets(widgets.filter((w) => w.tag === 'Analytics'));
        return () => {
            isMounted.current = false;
          };
    }, []);

    return (
        tooltipVisible && (
            <div className={generatingReport ? styles.tooltipContainerGenerating : styles.tooltipContainer}>
                {generatingReport ? <div><LoadingSpinner prependText={t('Generating Report')} size="4rem"/> </div> : (
                    <>
                    <div className={styles.tooltipBoxes}>
                    {availableAnalyticsWidgets
                        .filter((widget: any) => {
                            return (
                                widget.key !== 'topicMentionsDetailOverview' &&
                                widget.key !== 'hitsPerProfile' &&
                                widget.key !== 'reportTitlePage'
                            );
                        })
                        .map((widget: any) => {
                            return (
                                <div
                                    className={
                                        selectedAnalyticsWidgets.find(
                                            (w: any) => w.key === widget.key
                                        )
                                            ? styles.tooltipItemSelected
                                            : styles.tooltipItem
                                    }
                                    key={widget.key}
                                    onClick={() => {
                                        if (
                                          selectedAnalyticsWidgets.find(
                                            (w: any) => w.key === widget.key
                                          )
                                        ) {
                                          if (isMounted.current) {
                                            setSelectedAnalyticsWidgets(
                                              selectedAnalyticsWidgets.filter(
                                                (w: any) => w.key !== widget.key
                                              )
                                            );
                                          }
                                        } else {
                                          if (isMounted.current) {
                                            setSelectedAnalyticsWidgets([
                                              ...selectedAnalyticsWidgets,
                                              widget
                                            ]);
                                          }
                                        }
                                      }}
                                    style={{
                                        backgroundImage: `url(${
                                            widget.key === 'worldMap'
                                                ? geoBreakdown
                                                : widget.key === 'sentimentBreakdown'
                                                ? sentimentBreakdown
                                                : widget.key === 'scopeOfMentions'
                                                ? scopeOfMentionsRel
                                                : widget.key === 'scopeOfMentionsAbsolute'
                                                ? scopeOfMentionsRel
                                                : widget.key === 'languageBreakdown'
                                                ? languageBreakdown
                                                : widget.key === 'scopeBreakdown'
                                                ? scopeBreakdown
                                                : widget.key === 'mediaTypeBreakdown'
                                                ? mediaTypeBreakdown
                                                : widget.key ===
                                                  'topicMentionDetailsTable'
                                                ? mentionsTable
                                                : widget.key ===
                                                  'mentionsPerChannelAbsolute'
                                                ? mentionsPerChannel
                                                : ''
                                        })`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}
                                >
                                    <span
                                        className={
                                            selectedAnalyticsWidgets.find(
                                                (w: any) => w.key === widget.key
                                            )
                                                ? styles.tooltipItemSelectedText
                                                : styles.tooltipItemText
                                        }
                                    >
                                        {t(widget.name)}
                                    </span>
                                </div>
                            );
                        })}
                </div>
                <div className={styles.tooltipButtonContainer}>
                    <Button
                        type="primary"
                        btnClass={styles.tooltipButton}
                        disabled={selectedAnalyticsWidgets.length < 2}
                        onClick={
                            selectedAnalyticsWidgets.length > 1
                                ? () => {
                                      handleGenerateReport();
                                  }
                                : () => {
                                      alert('Please select at least one widget');
                                  }
                        }
                    >
                        {t('Create Report')}
                    </Button>
                    <Button
                        type="secondary"
                        btnClass={styles.tooltipButton}
                        onClick={() => {
                            setTooltipVisible(false);
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                </div>
                    </>
                )}
            </div>
        )
    );
};

export default ClipAnalytics;
