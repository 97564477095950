import React, { useState } from 'react';
import { dateToString } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import APIClips from 'src/API/APIClips';
import config from 'src/config/config';

const useClipCounts = ({
    isSearch,
    isEdited,
    profileGroups,
    currentProfileGroup,
    searchGroup
}: MentionsVisualizerTypes.UseClipCountsProps) => {

    const [fetchCountsTimeout, setFetchCountsTimeout] = useState<NodeJS.Timeout | null>(
        null
    );
    const [clipCounts, setClipCounts] = useState<{ [x: string]: number }>({});
    const [searchClipCounts, setSearchClipCounts] = useState<{ [x: string]: number }>({});

    const fetchClipCounts = async (
        selectedDateRange: MentionsVisualizerTypes.DateRange
    ) => {
        const profileGroupIds = profileGroups.map((group: { id: any }) => group.id);
        
        // Create an array of profiles for each group
        const profilesByGroup = profileGroupIds
            .map((groupId: any) => {
                const group = profileGroups.find((g: { id: any }) => g.id === groupId);
                return {
                    groupId,
                    profiles: group ? group.profiles : []
                };
            })
            .filter((group: { profiles: string | any[] }) => group.profiles.length > 0);

        if (profilesByGroup.length === 0) {
            setClipCounts({});
            return;
        }

        // Combine all profile IDs for a bulk API request
        const allProfileIds = profilesByGroup.flatMap((group: { profiles: any[] }) =>
            group.profiles.map((profile: { id: any }) => profile.id) || []
        );
        const allEditedIds = profilesByGroup.flatMap((group: { profiles: any[] }) =>
            group.profiles.map((profile: { id: string }) => profile.id + 'e') || []
        );

        // Make a single API call for all profiles
        const countsByProfile = await APIClips.getCountsByProfile(
            isEdited ? allEditedIds.join(',') : allProfileIds.join(','),
            dateToString(selectedDateRange?.startDate),
            dateToString(selectedDateRange?.endDate)
        ) || { data: [] };

        // Calculate total count for each group
        const countsData = countsByProfile?.data.reduce(
            (acc: { [x: string]: any }, profile: { profileID: any; _count: any }) => {
                const groupId = profilesByGroup.find((group: { profiles: any[] }) =>
                    group.profiles.some((p: { id: any }) => p.id === profile.profileID)
                )?.groupId;
                if (groupId) {
                    acc[groupId] = (acc[groupId] || 0) + profile._count;
                }
                return acc;
            },
            {}
        );

        isSearch && searchGroup ? setClipCounts(
            {
                ...countsData,
                [searchGroup?.id]: searchGroup?.profiles.reduce(
                    (acc: any, profile: { id: string | number }) => {
                        return acc + (searchClipCounts[profile.id] || 0);
                    },
                    0
                )
            }
        ) : setClipCounts(countsData);
    };

    const fetchSearchClipCounts = async (
        dateRange: MentionsVisualizerTypes.DateRange
    ) => {
        if (searchGroup && searchGroup.profiles.length > 0) {
            const searchProfileIds = searchGroup.profiles.map(
                (profile: { id: string }) => profile.id + 't'
            );
            const searchCountsByProfile = await APIClips.getCountsByProfile(
                searchProfileIds.join(','),
                dateToString(dateRange.startDate),
                dateToString(dateRange.endDate)
            );

            const searchCountsData = searchCountsByProfile.data.reduce(
                (acc: { [x: string]: any }, profile: { profileID: any; _count: any }) => {
                    const profileId = profile.profileID;
                    acc[profileId] = profile._count + (acc[profileId] || 0);
                    return acc;
                },
                {}
            );

            setSearchClipCounts(searchCountsData);
        }
    };

    const fetchCountsHandler = (startDate: Date, endDate: Date) => {
        if (fetchCountsTimeout) {
            clearTimeout(fetchCountsTimeout);
        }
        const timeout = setTimeout(() => {
            if (isSearch) {
                fetchSearchClipCounts({
                    startDate,
                    endDate
                });
            } else {
                fetchClipCounts({
                    startDate,
                    endDate
                });
            }
        }, 200);
        setFetchCountsTimeout(timeout);
    };

    return {
        clipCounts,
        searchClipCounts,
        setClipCounts,
        setSearchClipCounts,
        fetchCountsHandler,
        fetchClipCounts,
        fetchSearchClipCounts
    };
};

export default useClipCounts;
