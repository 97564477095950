import GenericCalls from "./genericCalls";

class APIClips {
    static async getClips(profileIds: string, startDate: string, endDate: string) {
        const url = `/api/analytics/_recent/select/profiles/${encodeURIComponent(profileIds)}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
}
    static async getCountsByProfile(profileId: string[] | string, startDate: string, endDate: string) {
        const url = `/api/analytics/_count/by/type,cc,profileID,userdate/select/profiles/${profileId}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async getEditedClips(profileId: string, startDate: string, endDate: string) {
        const url = `/api/analytics/_eFeed/select/profiles/${encodeURIComponent(profileId)}e?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async getSearchQueryClips(searchProfile: string, startDate: string, endDate: string) {
        const url = `/api/search?outputType=jsonTnotif&tnotProfileid=${searchProfile}&tnotQueryid=0&profileid=${searchProfile}&from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async makeClipsPermanent(profileID: string | number, notifIDs: string[]) {
        const url = `/api/search/results/make/permanent/${profileID}/${notifIDs}`;
        return await GenericCalls.get(url);
    }
}

export default APIClips;