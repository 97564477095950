import React, { useState, useEffect } from 'react';
import APIPushFeed from 'src/API/APIPushFeed';
import styles from './RSSFeed.module.scss';
import LoadingSpinner from '../basicElements/LoadingSpinner/LoadingSpinner';
import Button from '../basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { cacheBust } from '../helpers';

const escapeXMLSpecialChars = (xmlString: string) => {
  return xmlString
      .replace(/&(?!(amp;|lt;|gt;|quot;|apos;))/g, '&amp;')
};


const RSSFeed = () => {
  const [feedHTML, setFeedHTML] = useState('');
  const [xmlDocument, setXMLDocument] = useState('');
  const [loading, setLoading] = useState(false);
  const [startID, setStartID] = useState<number | null | string>(0);
  const [copyMessage, setCopyMessage] = useState('');

  const fetchRSSFeed = async () => {
      setLoading(true);
      try {
          const [rssResponse, xslResponse] = await Promise.all([
              APIPushFeed.get(startID, 60, undefined, 'XML', 'text'),
              fetch(cacheBust(`${process.env.PUBLIC_URL}/rss-stylesheet.xsl`))
          ]);

          const escapedRSSResponse = escapeXMLSpecialChars(rssResponse);
          setXMLDocument(escapedRSSResponse);

          if (!xslResponse.ok) {
              throw new Error(
                  `XSL stylesheet request failed with status ${xslResponse.status}`
              );
          }

          const rssText = escapedRSSResponse;
          const xslText = await xslResponse.text();

          const rssXML = new DOMParser().parseFromString(rssText, 'application/xml');
          const xslXML = new DOMParser().parseFromString(xslText, 'application/xml');

          // Extract startID from rssXML and set it to state
          const generatorElement = rssXML.getElementsByTagName('generator')[0];
          if (generatorElement) {
              setStartID(generatorElement.getAttribute('startID'));
          }

          const xsltProcessor = new XSLTProcessor();
          xsltProcessor.importStylesheet(xslXML);
          const resultDocument = xsltProcessor.transformToFragment(rssXML, document);

          setFeedHTML(new XMLSerializer().serializeToString(resultDocument));
      } catch (error) {
          console.error('Error fetching or transforming RSS feed:', error);
      } finally {
          setLoading(false);
      }
  };

  const copyRSSXML = () => {
      navigator.clipboard.writeText(xmlDocument);
      setCopyMessage('XML copied to clipboard!');
      setTimeout(() => setCopyMessage(''), 1000);
  }

  const downloadJSON = async () => {

    const jsonResponse = await APIPushFeed.get(startID, 60, undefined, 'json');
    const json = JSON.stringify(jsonResponse, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'rss-feed.json';
    a.click();
    URL.revokeObjectURL(url);

}
  useEffect(() => {
      fetchRSSFeed();
  }, []);

  return (
      <div className={styles.rssFeedContainer}>
          <h1>RSS Feed</h1>
          <div>
              <Button type='secondary' btnClass="primary" onClick={fetchRSSFeed}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />
                  Get next feeds
              </Button>
              <Button type='primary' btnClass="primary" onClick={copyRSSXML}>
                  <FontAwesomeIcon icon={faCopy} style={{ marginRight: '0.5rem' }} />
                  Copy RSS XML
              </Button>
                <Button type='primary' btnClass="primary" onClick={downloadJSON}>
                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '0.5rem' }} />
                    Download JSON
                </Button>
          </div>
          <hr style={{
              width: '100%',
              margin: '1.5rem 0 1rem 0',
              border: '1px solid #ccc'
          }}/>
          {copyMessage && <div className={styles.copyMessage}>{copyMessage}</div>}
          {loading ? <LoadingSpinner size='2rem'/> : 
          <div
              className="feed-container"
              dangerouslySetInnerHTML={{ __html: feedHTML }}
          />
          }
      </div>
  );
};

export default RSSFeed;