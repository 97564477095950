import React from 'react';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';

const useFilterChange = ({
    filter,
    setFilter,
    fetchCountsHandler
}: MentionsVisualizerTypes.UseFilterChangeProps) => {
    // handle filter change event and set filter state accordingly
    const handleFilterChange = (value: any, actionMeta: any) => {
        if (actionMeta) {
            const { name } = actionMeta;
            if (name === 'dateRange') {
                // Update date range based on selected value
                const today = new Date();
                let startDate = new Date();
                let endDate = new Date();
                switch (value.value) {
                    case 'Today':
                        startDate = new Date(today);
                        endDate = new Date(today);
                        break;
                    case 'Yesterday':
                        startDate = new Date(today);
                        startDate.setDate(startDate.getDate() - 1);
                        endDate = new Date(today);
                        endDate.setDate(endDate.getDate() - 1);
                        break;
                    case 'Last 7 days':
                        startDate = new Date(today);
                        startDate.setDate(startDate.getDate() - 7);
                        break;
                    case 'Last 30 days':
                        startDate = new Date(today);
                        startDate.setDate(startDate.getDate() - 30);
                        break;
                    case 'This month':
                        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                        break;
                    case 'Last month':
                        startDate = new Date(
                            today.getFullYear(),
                            today.getMonth() - 1,
                            1
                        );
                        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                        break;
                    default:
                        break;
                }
                
                setFilter({
                    ...filter,
                    [name]: value,
                    dateRange: { startDate, endDate, customRange: value.value }
                });
                fetchCountsHandler(startDate, endDate);
            } else {
                setFilter({ ...filter, [name]: value });
            }
        }
    };

    return {
        handleFilterChange
    };
};

export default useFilterChange;
