import React, { useEffect, useState } from 'react';
import styles from './ClipCluster.module.scss';

import useClipsFilters from '../hooks/useClipsFilters';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import ChannelsColumn from './ChannelsColumn/ChannelsColumn';
import ClipColumn from './ClipColumn/ClipColumn';
import CountryColumn from './CountryColumn/CountryColumn';
import ClusterHeader from './ClusterHeader/ClusterHeader';
import ClipAnalytics from './ClipAnalytics/ClipAnalytics';
import { useAppSelector } from 'src/redux/hooks';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';

const ClipCluster = ({
    profileGroupContent,
    currentProfileGroup,
    data,
    filter,
    selectedDateRange,
    isSearch,
    isEdited,
    isMentionFindrApp
}: MentionsVisualizerTypes.ClipClusterProps) => {
    const processedIndices: Set<number> = new Set();

    const [fetchedItems, setFetchedItems] = useState<Set<number>>(new Set());
    const [clipDetail, setClipDetail] = useState<MentionsVisualizerTypes.ClipDetailType>(
        {} as MentionsVisualizerTypes.ClipDetailType
    );
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [backFillSelectionVisible, setBackFillSelectionVisible] = useState(false);

    const channelGroups = useAppSelector((state) => state.channelGroupReducers);

    const {
        activeFilter,
        setActiveFilter,
        handleChannelFilter,
        handleCountryFilter,
        handleProfileFilter,
        handleSearchChannels,
        handleTypeFilter,
        hasTypeInGroup,
        hasTypeInProfile,
        handleLanguageFilter,
        handleSentimentFilter,
        searchChannelsInput,
        setSearchChannelsInput,
        filteredChannelIcons,
        setFilteredChannelIcons,
        filteredDataToDisplay,
        listKey,
        filterData,
        hasSentiment,
        sortedUniqueChannels,
        sortedUniqueCountries,
        getLanguagesByCountry,
        selectedLanguages
    } = useClipsFilters({
        profileGroupContent,
        setClipDetail,
        setFetchedItems,
        processedIndices
    });

    //fetch again when active filter changes
    useEffect(() => {
        filterData();
    }, [activeFilter, selectedLanguages]);

    //fetch again when data changes in search
    useEffect(() => {
        if (isSearch) {
            filterData();
        }
    }, [data]);


    const checkChannelGroup = (channelNumber: number, selectedChannelGroup: {
        value: number;
        label: string;
    } | undefined ): string => {
        let channelGroupTitle = '';
        channelGroups.forEach((channelGroup: ChannelGroup) => {
            if (
                channelGroup.channels.filter((channel) => channel.id === channelNumber)
                    .length > 0 && selectedChannelGroup?.value === channelGroup.id
            ) {
                channelGroupTitle = channelGroup.title;
            }
        });
        return channelGroups && filter.selectedChannelGroup
            ? channelGroupTitle
            : '';
    }
    
    return (
        <div className={styles.clipClusterContainer} style={{}}>
            <ClusterHeader
                profileGroupContent={profileGroupContent}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                handleProfileFilter={handleProfileFilter}
                selectedDateRange={selectedDateRange}
                isSearch={isSearch}
                tooltipVisible={tooltipVisible}
                setTooltipVisible={setTooltipVisible}
                backFillSelectionVisible={backFillSelectionVisible}
                setBackFillSelectionVisible={setBackFillSelectionVisible}
                selectedChannelGroup={
                    checkChannelGroup(
                        filteredDataToDisplay.length > 0
                            ? filteredDataToDisplay[0].channelNumber
                            : 0, filter.selectedChannelGroup
                    )
                }
            />
            <div className={styles.clusterMiddleContainer}>
                <ChannelsColumn
                    hasTypeInGroup={hasTypeInGroup}
                    hasTypeInProfile={hasTypeInProfile}
                    hasSentiment={hasSentiment}
                    handleTypeFilter={handleTypeFilter}
                    handleSentimentFilter={handleSentimentFilter}
                    handleSearchChannels={handleSearchChannels}
                    handleChannelFilter={handleChannelFilter}
                    profileGroupContent={profileGroupContent}
                    searchChannelsInput={searchChannelsInput}
                    setSearchChannelsInput={setSearchChannelsInput}
                    setFilteredChannelIcons={setFilteredChannelIcons}
                    sortedUniqueChannels={sortedUniqueChannels}
                    setActiveFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    listKey={listKey}
                    isEdited={isEdited}
                    filteredChannelIcons={filteredChannelIcons}
                />
                <ClipColumn
                    listKey={listKey}
                    filteredDataToDisplay={filteredDataToDisplay}
                    currentProfileGroup={currentProfileGroup}
                    isSearch={isSearch}
                    processedIndices={processedIndices}
                    fetchedItems={fetchedItems}
                    setFetchedItems={setFetchedItems}
                    clipDetail={clipDetail}
                    setClipDetail={setClipDetail}
                    isEdited={isEdited}
                    activeFilter={activeFilter}
                    isMentionFindrApp={isMentionFindrApp}
                    backFillSelectionVisible={backFillSelectionVisible}
                    setBackFillSelectionVisible={setBackFillSelectionVisible}
                />
                <CountryColumn
                    handleCountryFilter={handleCountryFilter}
                    handleLanguageFilter={handleLanguageFilter}
                    getLanguagesByCountry={getLanguagesByCountry}
                    sortedUniqueCountries={sortedUniqueCountries}
                    activeFilter={activeFilter}
                    selectedLanguages={selectedLanguages}
                />
                <ClipAnalytics
                    selectedDateRange={selectedDateRange}
                    setTooltipVisible={setTooltipVisible}
                    tooltipVisible={tooltipVisible}
                    profileGroupContent={profileGroupContent}
                    activeFilter={activeFilter}
                    isEdited={isEdited}
                    isSearch={isSearch}
                />
            </div>
        </div>
    );
};

export default ClipCluster;