import React, { useEffect, useRef, useState } from 'react';
import styles from './ClipFilters.module.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getGroups } from 'src/redux/actions/actions.channelGroups';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import ClipFiltersTopBar from './ClipFiltersTopBar/ClipFiltersTopBar';
import useClipCounts from '../hooks/useClipCounts';
import ClipFiltersSideBar from './ClipFiltersSideBar/ClipFiltersSideBar';
import config from 'src/config/config';
import { dateToString } from 'src/Widgets/common/helpers';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';

const ClipFilters: React.FC<MentionsVisualizerTypes.ClipFiltersProps> = ({
    applyFilters,
    isToggleChannels,
    setIsToggleChannels,
    data,
    isEdited,
    isRecent,
    isSearch,
    isFilterVisible,
    setFilterVisible,
    isSelectChannelsShown,
    setIsSelectChannelsShown,
    setFilter,
    filter,
    fetchSearchClips,
    defaultProfileGroup,
    setModalType,
    modalType,
    tryQuery,
    isDateRangeSet,
    setIsDateRangeSet
}) => {

    const [activeSearchHistoryItemId, setActiveSearchHistoryItemId] = useState<
        number | null
    >(null);

    const filterRef = useRef(null);

    // get profile groups from redux store
    const profileGroups = useAppSelector((state) => state.profileGroups.data);
    const dispatch = useAppDispatch();

    const searchGroup = profileGroups.find(
        (group: { title: string }) => group.title === config.searchQueryGroupTitle
    );

    const { clipCounts, searchClipCounts, fetchCountsHandler } =
        useClipCounts({
            profileGroups,
            searchGroup,
            currentProfileGroup: filter.profileGroup,
            isSearch,
            isEdited
        });

    //fetch channel groups
    useEffect(() => {
        dispatch(getGroups());
    }, []);

    useEffect(() => {
        if(profileGroups.length === 0){
            dispatch(getProfileGroups());
        }
    }, [])

    // set default profile group
    useEffect(() => {
        if (defaultProfileGroup && !isSearch) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                profileGroup: {
                    value: {
                        id: defaultProfileGroup.id,
                        title: defaultProfileGroup.title
                    },
                    label: defaultProfileGroup.title,
                    profiles: defaultProfileGroup.profiles
                }
            }));
        }

            fetchCountsHandler(
                dateToString(filter.dateRange?.startDate),
                dateToString(filter.dateRange?.endDate)
            );
        
    }, []);

    return (
        <div
            className={styles.clipFiltersContainer}
            style={isRecent || isEdited ? { alignItems: 'flex-start' } : {}}
            ref={filterRef}
        >
            {
                <ClipFiltersTopBar
                    setFilterVisible={setFilterVisible}
                    isFilterVisible={isFilterVisible}
                    isSearch={isSearch}
                    isEdited={isEdited}
                    isRecent={isRecent}
                    fetchSearchClips={fetchSearchClips}
                    setFilter={setFilter}
                    setActiveSearchHistoryItemId={setActiveSearchHistoryItemId}
                    isSelectChannelsShown={isSelectChannelsShown}
                    setIsSelectChannelsShown={setIsSelectChannelsShown}
                    activeSearchHistoryItemId={activeSearchHistoryItemId}
                    setModalType={setModalType}
                    modalType={modalType}
                    filter={filter}
                    applyFilters={applyFilters}
                    filterRef={filterRef}
                    tryQuery={tryQuery}
                    isDateRangeSet={isDateRangeSet}
                />
            }

            <ClipFiltersSideBar
                isFilterVisible={isFilterVisible}
                setFilterVisible={setFilterVisible}
                filter={filter}
                applyFilters={applyFilters}
                filterRef={filterRef}
                data={data}
                searchGroup={searchGroup}
                setFilter={setFilter}
                fetchSearchClips={fetchSearchClips}
                clipCounts={clipCounts}
                searchClipCounts={searchClipCounts}
                isSelectChannelsShown={isSelectChannelsShown}
                setIsSelectChannelsShown={setIsSelectChannelsShown}
                fetchCountsHandler={fetchCountsHandler}
                isSearch={isSearch}
                isEdited={isEdited}
                isRecent={isRecent}
                activeSearchHistoryItemId={activeSearchHistoryItemId}
                setActiveSearchHistoryItemId={setActiveSearchHistoryItemId}
                tryQuery={tryQuery}
                setIsDateRangeSet={setIsDateRangeSet}
                isToggleChannels={isToggleChannels}
                setIsToggleChannels={setIsToggleChannels}
            />
        </div>
    );
};

export default ClipFilters;
