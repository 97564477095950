import { useTranslation } from "react-i18next";
import Button from "src/Widgets/common/basicElements/Button/Button";
import loginStyles from 'src/Login/Login.module.scss';
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";

function ForgotPassword({
    sendPwLinkError,
    sentPwLink,
    inputClass,
    handleUsernameChange,
    sendResetPasswordLink,
    username,
    onBack,
    setRefUser,
}) {

    const { t } = useTranslation();
    
    return (
        <>
            <div>
                {
                    sendPwLinkError
                        ? <span className={`${loginStyles.loginFailed} animated bounceIn`}>{sendPwLinkError}</span>
                        : sentPwLink
                            ? <div style={{ flexDirection: 'column' }}>
                                <h2>{t('forgot_pw_send_email_done_text1')} {username}</h2>
                                <span>{t('forgot_pw_send_email_done_text2')}.</span>
                            </div>
                            : t('forgot_pw_send_email_text')
                }
            </div>

            {
                sentPwLink
                    ?
                    <Button type='primary' onClick={e => { onBack(e) }}>
                        {t('Back')}
                    </Button>
                    : <>
                        <TextInput
                            inputType="text"
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder={t("Username")}
                            className={[loginStyles.textInputWrapper,].join(' ')}
                            style={{marginBlockStart:0}}
                            onKeyPress={e => e.key === 'Enter' && sendResetPasswordLink()}
                            // ref={(r) => { setRefUser(r); }}
                        />
                        <Button
                            disabled={sendPwLinkError || !username}
                            type='secondary'
                            onClick={e => sendResetPasswordLink()}
                            style={{ width: '9.5rem' }}
                        >
                            {t('Send e-mail')}
                        </Button>
                    </>
            }
        </>
    )
}

export default ForgotPassword;