import './LoadingSpinner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinnerProps } from './LoadingSpinner.types';
import { FC } from 'react';

// fontawesome icons
library.add(faCog);

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    // iconSize,
    wrapperFullSize,
    prependText,
    size,
}:LoadingSpinnerProps) => {

    let styleClasses = [
        "loading-spinner-wrapper",
    ];
    if(wrapperFullSize !== false){
        styleClasses.push("full-size");
    }

    return (
        <div className={styleClasses.join(' ')}>
            {prependText ? <div className="prepend-text">{prependText}</div> : null}
            <FontAwesomeIcon
                className="loading-spinner"
                style={{fontSize: size}}
                icon="cog"
                spin
            />
        </div>
    );
}

export default LoadingSpinner;
