/**
 * props:
 *  - placeholder - optional
 *  - label
 *  - labelRight - optional
 *  - value
 *  - onChange(e)
 *  - onKeyPress(e)
 *  - onBlur(e)
 *  - onFocus(e)
 *  - disabled
 *  - className - optional
 *  - isArea - optional
 *  - isRequired - optional
 *  - numbersOnly - optional
 *  - inputType - optional
 *  - min - optional
 *  - max - optional
 *  - invalid - optional
 *  - showPlaceholder - optional; default = true
 */
/* class TextInput extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.invalidStyle = [
            this.props.theme.backgroundDangerLight,
            this.props.theme.borderDanger,
            styles.invalid
        ];
    }
    
    onChange(e) {
        if (e.target.value && this.props.numbersOnly && !/^\d+$/.test(e.target.value))
            return;
        if(e.target.value && this.props.max && e.target.value > this.props.max)
            return;
        if(e.target.value && this.props.min && e.target.value < this.props.min)
            return;

        this.props.onChange(e);
    }

    render() {
        const { theme } = this.props;

        let wrapperClasses = this.props.className + ' ' + styles.inputGroup;

        const inputProps = {
            placeholder:
                this.props.showPlaceholder !== false ?
                    (this.props.placeholder || this.props.label) +
                    (this.props.isRequired ? '*' : '')
                : undefined
            ,
            value: this.props.value || '',
            onChange: this.onChange,
            onKeyPress: this.props.onKeyPress,
            onBlur: this.props.onBlur,
            onFocus: this.props.onFocus,
            disabled: this.props.disabled,
        };

        let input = this.props.isArea ? (
            <TextareaAutosize
                className={classNames(
                    styles.input,
                    styles.area,
                    { [theme.borderPrimary]: !this.props.invalid && this.props.isRequired },
                    this.props.invalid ? this.invalidStyle : '',
                )}
                {...inputProps}
            />
        ) : (
            <div className={this.props.icon && styles.inputWithIcon}>
            <input
                ref={this.props.inputRef}
                type={this.props.inputType ? this.props.inputType : "text"}
                aria-label={this.props.label}
                className={classNames(
                    styles.input,
                    { [theme.borderPrimary]: this.props.isRequired },
                    this.props.invalid ? this.invalidStyle : '',
                    this.props.inputClassName ? this.props.inputClassName : ''
                )}
                {...inputProps}
            />
            {this.props.icon && (
                    <div className={styles.iconContainer}>
                        <FontAwesomeIcon icon={this.props.icon} />
                    </div>
                )}
            </div>
        );

        return (
            <div className={wrapperClasses} style={this.props.style}>
                {(this.props.label || this.props.labelRight) && <span
                    className={
                        classNames(
                            styles.textfieldLabel,
                            {
                                [this.props.labelDisabled ? styles.disabled : theme.textPrimary]: this.props.isRequired,
                                [theme.borderPrimary]: this.props.isRequired,
                            },
                            this.props.invalid ? theme.textDanger : '',
                        )}
                >
                    {this.props.label}
                    {this.props.isRequired && '*'}
                    {this.props.labelAppend && ` - ${this.props.labelAppend}`}
                    <span className={styles.labelRight}>{this.props.labelRight}</span>
                </span>}

                {input}
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(TextInput);
 */

import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-autosize-textarea';
import classNames from 'classnames';
import styles from './TextInput.module.scss';
import { TextInputProps } from './TextInput.types';
import { useAppSelector } from 'src/redux/hooks';

const TextInput: FC<TextInputProps> = ({
    className,
    numbersOnly,
    max,
    min,
    onChange,
    showPlaceholder,
    placeholder,
    label,
    isRequired,
    value,
    onKeyPress,
    onBlur,
    onFocus,
    disabled,
    isArea,
    icon,
    inputRef,
    inputType,
    inputClassName,
    labelDisabled,
    labelRight,
    labelAppend,
    invalid,
    style,
  }) => {

    const theme = useAppSelector(state => state.theme);
  
    const getInvalidStyle = (): string[] => {
      return invalid
        ? [theme.backgroundDangerLight, theme.borderDanger, styles.invalid]
        : [];
    };
  
    const validateInput = (inputValue: string): boolean => {
      if (numbersOnly && !/^\d*$/.test(inputValue)) {
        return false;
      }
      if (max && parseFloat(inputValue) > max) {
        return false;
      }
      if (min && parseFloat(inputValue) < min) {
        return false;
      }
      return true;
    };
  
    const getInputProps = (): any => {
      const inputIndicator = isRequired ? '*' : '';
      const placeholderValue = showPlaceholder !== false ? placeholder ?? label + inputIndicator : undefined;
      return {
        placeholder: placeholderValue,
        value: value,
        onChange: handleChange,
        onKeyPress,
        onBlur,
        onFocus,
        disabled,
      };
    };
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (validateInput(e.target.value)) {
        onChange(e);
      }
    };
  
    const renderInput = (): React.JSX.Element => {
      if (isArea) {
        return (
          <TextareaAutosize
            className={classNames(
              styles.input,
              styles.area,
              { [theme.borderPrimary]: !invalid && isRequired },
              ...getInvalidStyle()
            )}
            {...getInputProps()}
          />
        );
      } else {
        const inputElement = (
          <input
            ref={inputRef}
            type={inputType || 'text'}
            aria-label={label}
            className={classNames(
              styles.input,
              { [theme.borderPrimary]: isRequired },
              ...getInvalidStyle(),
              inputClassName
            )}
            {...getInputProps()}
          />
        );
  
        return icon ? (
          <div className={styles.inputWithIcon}>
            {inputElement}
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={icon} />
            </div>
          </div>
        ) : (
          inputElement
        );
      }
    };
  
    const renderLabel = (): React.JSX.Element | null => {
      if (!label && !labelRight) {
        return null;
      }
  
      return (
        <span
          className={classNames(
            styles.textfieldLabel,
            {
              [labelDisabled ? styles.disabled : theme.textPrimary]: isRequired,
              [theme.borderPrimary]: isRequired,
              [theme.textDanger]: invalid,
            }
          )}
        >
          {label}
          {isRequired && '*'}
          {labelAppend && ` - ${labelAppend}`}
          <span className={styles.labelRight}>{labelRight}</span>
        </span>
      );
    };
  
    const wrapperClasses = classNames(className, styles.inputGroup);
  
    return (
      <div className={wrapperClasses} style={style}>
        {renderLabel()}
        {renderInput()}
      </div>
    );
  };
  
  export default TextInput;
  