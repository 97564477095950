/* class EPG extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iFrameUrl: null,
            content: [],
            timezone: 'unknown',
            history: [],
            filter: {
                selectedChannel: null,
                date: new Date(),
                hour: undefined,   // NOTE: (future todo) hours, minutes may be added to date instead of separate variables (as this is already the case for when storing them in history)
                minute: undefined
            }
        };
        this.historySettingName = 'epgHistory';

        this.minuteButtonRef = undefined;

        this.changeIFrameUrl = this.changeIFrameUrl.bind(this);
        this.changeContent = this.changeContent.bind(this);
        this.searchForContent = this.searchForContent.bind(this);
        this.onUse = this.onUse.bind(this);
        this.onHistoryDelete = this.onHistoryDelete.bind(this);
        this.onHistoryRowClick = this.onHistoryRowClick.bind(this);
        this.onChannelChange = this.onChannelChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteButtonClick = this.onMinuteButtonClick.bind(this);
        this.onEpgClick = this.onEpgClick.bind(this);
    }

    async componentDidMount() {
        let user = (await APIMe.get()).data;
        let channels = (await APIChannels.get()).data;
        if (!user || user.status) return;
        
        this.props.getEpgHistory();

        this.setState({
            timezone: user.timezone,
            channels,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.epgHistory !== this.props.epgHistory) {
            this.setState({ history: this.props.epgHistory });
        }
    }
    

    convertHistory(history) { // get api response ready to be rendered by history component        
        history = _.map(history, (o) => {
            return {
                textLeft: <><FontAwesomeIcon icon="image" /> { o.channel }</>,
                textMiddle: dateToString(timestampToDate(o.timestamp)),
                textRight: o.program ? o.program : dateToTimeString(timestampToDate(o.timestamp))
            }           
        })
        
        return history;
    }

    changeIFrameUrl = iFrameUrl => this.setState({ iFrameUrl });

    async changeContent(channel, date) {
        if (channel === null || date === null) this.setState({ content: [] });
        else {
            const content = (await APIEpg.getEPG(channel.value, dateToString(date), channel.label)).data;

            if (!content || content.status) return;

            this.setState({
                content,
                iFrameUrl: null
            });
        }
    }

    async searchForContent(term, from, to) {
        const content =
            (await APIEpg.getEPGBySearch(
                term,
                dateToString(from),
                dateToString(to),
                this.state.filter.selectedChannel.value
            )).data;

        if (!content || content.status) return;

        this.setState({
            content,
            iFrameUrl: null
        });
    }

    async onUse(channelId, channelName, ts, program) {    // add history items each time epg gets used
        let item = {
            channelId: channelId,
            channel: channelName,
            timestamp: ts,
            program: program,
        }
        this.saveHistory(item);
    }

    saveHistory = async (item) => {
        return await this.props.postEpgHistoryItem(item);
    }

    onHistoryDelete(index) {
        const itemId = this.state.history[index].id;
        this.props.deleteEpgHistoryItem(itemId);
    }

    onHistoryRowClick(clickedRowIndex) {
        const item = this.state.history[clickedRowIndex];
        const channel = _.find(this.state.channels, { id: item.channelId });
        let filter = _.cloneDeep(this.state.filter);
        filter.selectedChannel = {
            value: item.channelId,
            label: item.channel,
            mediaType: channel.mediaType,
        };        
        filter.date = timestampToDate(item.timestamp);
        filter.hour = filter.date.getHours();
        filter.minute = filter.date.getMinutes();

        // if date or channel is changed due to history click, remove fetched epg entries
        let content = [];
        if (
            filter?.selectedChannel?.value === this.state.filter?.selectedChannel?.value &&
            areDatesEqual(filter.date, this.state.filter.date)
        ) {
            content = this.state.content;
        }

        this.setState({
            filter,
            content,
        }, () => {
            
            if (item.program) {
                this.onEpgClick(item.channelId, item.channel, item.timestamp, item.program, channel.mediaType);
            } else {
                this.onMinuteButtonClick(true, channel.mediaType);
            }
        });
    }

    onChannelChange(selectedChannel, date) {
        this.setState(state => {
            this.changeContent(selectedChannel, date);
            let filter = _.cloneDeep(state.filter);
            filter.selectedChannel = selectedChannel;
            return { filter };
        });
    }
    onDateChange(date) {
        this.setState(state => {
            this.changeContent(this.state.filter.selectedChannel, date);
            let filter = _.cloneDeep(state.filter);
            filter.date = date;
            return { filter };
        });
    }
    onMinuteChange(event) {
        if (_.inRange(event.target.value, 0, 60)) {
            this.setState((state) => {
                let filter = _.cloneDeep(state.filter);
                filter.minute = event.target.value;
                return { filter }
            });
        }
    }
    onHourChange(event) {
        if (_.inRange(event.target.value, 0, 24)) { // maybe change this to if > than allowed set max val (23), if < than allowed set min val (0)
            this.setState((state) => {
                let filter = _.cloneDeep(state.filter);
                filter.hour = event.target.value;
                return { filter }
            });
        } 
    }

    getBaseUrl(channelMediaType) {
        // returns url based on mediaType of channel
        // mediaEditor for tv, radio
        // stview for others
        return [1, 2].includes(channelMediaType)
            ? `${config.urls.mediaEditor}&`
            : '/cgi-bin/feed/stview?';
    }

    onEpgClick(channelId, channelName, startTS, title, channelMediaType) {
        this.onUse(channelId, channelName, startTS, title);

        return this.changeIFrameUrl(
            this.getBaseUrl(channelMediaType) + '&story=u:' +
            channelName +
            ':' +
            startTS +
            '&timezone=' +
            this.state.timezone
        )
    }

    onMinuteButtonClick(history=true, channelMediaType) {
        const selectedDate = this.state.filter.date;
        const storyTS =
            new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                this.state.filter.hour,
                this.state.filter.minute,
            ).getTime() / 1000;
        const channel = this.state.filter.selectedChannel;

        if (channel !== undefined) {
            this.changeIFrameUrl(
                this.getBaseUrl(channelMediaType) + '&story=u:' +
                channel.label +
                ':' +
                storyTS +
                '&timezone=' +
                this.state.timezone
            );
            if (history) { this.onUse(channel.value, channel.label, storyTS); }
        }  
    }

    render() {
        const history = this.convertHistory(this.state.history);
        const f = this.state.filter;
        const selectedChannel = f ? f.selectedChannel : null;
        
        return (
            <div className="epg">
                <EPGFilter
                    changeIFrameUrl={this.changeIFrameUrl}
                    changeContent={this.changeContent}
                    searchForContent={this.searchForContent}
                    timezone={this.state.timezone}
                    onUse={this.onUse}
                    selectedChannel={selectedChannel}
                    onChannelChange={this.onChannelChange}
                    date={this.state.filter.date}
                    onDateChange={this.onDateChange}
                    minute={this.state.filter.minute}
                    onMinuteChange={this.onMinuteChange}
                    hour={this.state.filter.hour}
                    onHourChange={this.onHourChange}
                    onMinuteButtonClick={(...params) => this.onMinuteButtonClick(...params, selectedChannel.mediaType)}
                    channels={this.state.channels}
                />
                <EPGContent
                    changeIFrameUrl={this.changeIFrameUrl}
                    iFrameUrl={this.state.iFrameUrl}
                    content={this.state.content}
                    timezone={this.state.timezone}
                    onUse={this.onUse}
                    onEpgClick={(...params)=>this.onEpgClick(...params, selectedChannel.mediaType)}
                />
                {
                    history.length > 0 && 
                    <div style={{width: '262px'}}>
                        <History
                            data={history}
                            onClick={this.onHistoryRowClick}
                            onDelete={this.onHistoryDelete}
                            />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ epgHistory: state.epgHistory });

export default connect(mapStateToProps, { getEpgHistory, postEpgHistoryItem, deleteEpgHistoryItem })(EPG); */

import { useState, useEffect, ChangeEvent } from 'react';

import EPGFilter from './EPGFilter/EPGFilter';
import EPGContent from './EPGContent/EPGContent';
import History from '../common/History/History';
import {
    areDatesEqual,
    dateToString,
    dateToTimeString,
    timestampToDate
} from '../common/helpers';
import APIMe from '../../API/APIMe';
import APIEpg from '../../API/APIEpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import {
    getEpgHistory,
    postEpgHistoryItem,
    deleteEpgHistoryItem
} from '../../redux/actions/actions.epgHistory';
import APIChannels from 'src/API/APIChannels';
import { EPGChannel } from './EPGFilter/EPGFilter.types';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { EPGHistoryChannel, Filter } from './EPG.types';
import { Channel } from 'src/@types/shared.types';
import style from './EPG.module.scss'
import useEPG from './useEPG';

const EPG = () => {
    const [iFrameUrl, setIFrameUrl] = useState<string>('');
    const [content, setContent] = useState([]);
    const [timezone, setTimezone] = useState('unknown');
    const [history, setHistory] = useState<EPGHistoryChannel[]>([]);
    const [channels, setChannels] = useState([]);
    const [filter, setFilter] = useState<Filter>({
        selectedChannel: null,
        date: new Date(),
        hour: undefined,
        minute: undefined
    });

    const dispatch = useAppDispatch();
    const epgHistory = useAppSelector((state) => state.epgHistory);

    const { createShowMedialUrl } = useEPG();

    /*   const historySettingName = 'epgHistory'; */

    //! program data coming undefined

    const changeIFrameUrl = (iFrameUrl: string) => setIFrameUrl(iFrameUrl);

    const convertHistory = (history: EPGHistoryChannel[]) => {

        return history?.map((o) => ({
            textLeft: (
                <>
                    <FontAwesomeIcon icon={faImage} /> {o.channel}
                </>
            ),
            textMiddle: dateToString(timestampToDate(o.timestamp)),
            textRight: o.program
                ? o.program
                : dateToTimeString(timestampToDate(o.timestamp))
        }));
    };

    const changeContent = async (channel: EPGChannel, date: Date) => {
        if (channel === null || date === null) {
            setContent([]);
        } else {
            const response = await APIEpg.getEPG(
                channel.value,
                dateToString(date),
                channel?.label
            );
            const contentData = response.data;

            if (!contentData || contentData.status) return;

            setContent(contentData);
            setIFrameUrl('');
        }
    };

    const searchForContent = async (term: string, from: Date, to: Date) => {
        const response = await APIEpg.getEPGBySearch(
            term,
            dateToString(from),
            dateToString(to),
            filter?.selectedChannel?.value
        );
        const contentData = response.data;

        if (!contentData || contentData.status) return;

        setContent(contentData);
        setIFrameUrl('');
    };

    const onUse = (channelId: number, channelName: string, ts: number, program: any) => {
        const item: EPGHistoryChannel = {
            channelId: channelId,
            channel: channelName,
            timestamp: ts,
            program: program
        };
        saveHistory(item);
    };

    const saveHistory = (item: EPGHistoryChannel) => {
        dispatch(postEpgHistoryItem(item));
    };

    const onHistoryDelete = (index: number) => {
        const itemId = history[index].id;
        dispatch(deleteEpgHistoryItem(itemId));
    };

    const onHistoryRowClick = (clickedRowIndex: number) => {
        const item = history[clickedRowIndex];
    
        const findChannel = (channels: Channel[], channelId: number) => {
            for (const channel of channels) {
                if (channel.id === channelId) {
                    return channel;
                }
            }
            return null;
        };
    
        const channel = findChannel(channels, item.channelId);
        const newFilter = { ...filter };
        if (channel) {
            newFilter.selectedChannel = {
                value: item.channelId,
                label: item.channel,
                mediaType: channel.mediaType
            };
            newFilter.date = timestampToDate(item.timestamp);
            newFilter.hour = newFilter.date.getHours();
            newFilter.minute = newFilter.date.getMinutes();
    
            let newContent: any = [];
            if (
                newFilter?.selectedChannel?.value === filter?.selectedChannel?.value &&
                areDatesEqual(newFilter.date, filter.date)
            ) {
                newContent = content;
            }
    
            setFilter(newFilter);
            setContent(newContent);
    
            if (item) {
                onEpgClick(
                    item.channelId,
                    item.channel,
                    item.timestamp,
                    item.program,
                    channel.mediaType
                );
            } else {
                onMinuteButtonClick(channel.mediaType, true);
            }
        }
    };
    

    const onChannelChange = (selectedChannel: EPGChannel, date: Date) => {
        setFilter((prevFilter) => {
            changeContent(selectedChannel, date);
            const newFilter = { ...prevFilter };
            newFilter.selectedChannel = selectedChannel;
            return newFilter;
        });
    };

    const onDateChange = (date: Date) => {
        setFilter((prevFilter) => {
            if (prevFilter.selectedChannel !== null) {
                changeContent(prevFilter.selectedChannel, date);
            }
            const newFilter = { ...prevFilter };
            newFilter.date = date;
            return newFilter;
        });
    };
    

    const onMinuteChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(event.target.value, 10);

        if (inputValue >= 0 && inputValue < 60) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                minute: inputValue
            }));
        }
    };

    const onHourChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(event.target.value, 10);

        if (inputValue >= 0 && inputValue < 24) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                hour: inputValue
            }));
        }
    };

    

    const onEpgClick = (channelId: number, channelName: string, startTS: number, title: string, channelMediaType: number) => {
        onUse(channelId, channelName, startTS, title);
        
        const url = createShowMedialUrl(channelMediaType, channelId, startTS, timezone);
        changeIFrameUrl(url);
    };

    const onMinuteButtonClick = (channelMediaType: number, history = true) => {
        const selectedDate = filter.date;
        const storyTS =
            new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                filter.hour,
                filter.minute
            ).getTime() / 1000;
        const channel = filter.selectedChannel;

        if (channel !== undefined && channel?.value !== undefined) {
            const url = createShowMedialUrl(channelMediaType, channel?.value, storyTS, timezone);
            changeIFrameUrl(url);

            if (history) {
               if(channel !== null){
                 onUse(channel.value, channel.label, storyTS, '');
               }
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await APIMe.get();
            const channelsResponse = await APIChannels.get();

            const user = userResponse.data;
            const channelsData = channelsResponse.data;

            if (!user || user.status) return;
            dispatch(getEpgHistory());
            setTimezone(user.timezone);
            setChannels(channelsData);
        };

        fetchData();
    }, [getEpgHistory, dispatch]);

    useEffect(() => {
        if (epgHistory !== history) {
            setHistory(epgHistory);
        }
    }, [epgHistory]);

    const convertedHistory = convertHistory(history);
    const selectedChannel = filter ? filter.selectedChannel : null;

    return (
        <div className={style.epg}>
            <EPGFilter
                changeIFrameUrl={changeIFrameUrl}
                changeContent={changeContent}
                searchForContent={searchForContent}
                timezone={timezone}
                onUse={onUse}
                selectedChannel={selectedChannel}
                onChannelChange={onChannelChange}
                date={filter.date}
                onDateChange={onDateChange}
                minute={filter.minute}
                onMinuteChange={onMinuteChange}
                hour={filter.hour}
                onHourChange={onHourChange}
                onMinuteButtonClick={() =>
                    {
                        if(selectedChannel !== null){
                            onMinuteButtonClick(selectedChannel.mediaType, true)
                        }
                    }
                }
                channels={channels}
            />
            <EPGContent
                changeIFrameUrl={changeIFrameUrl}
                iFrameUrl={iFrameUrl}
                content={content}
                timezone={timezone}
                onUse={onUse}
                onEpgClick={(...params: Parameters<typeof onEpgClick>) =>
                    {
                        if (selectedChannel) {
                            //@ts-ignore
                            onEpgClick(...params, selectedChannel.mediaType);
                        }
                }}
            />
            {convertedHistory.length > 0 && (
                <div style={{ width: '262px' }}>
                    <History
                        data={convertedHistory}
                        onClick={onHistoryRowClick}
                        onDelete={onHistoryDelete}
                    />
                </div>
            )}
        </div>
    );
};

export default EPG;
