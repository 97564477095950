import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import '../../assets/animate.css';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleEditButton from './ToggleEditButton/ToggleEditButton';
import classNames from 'classnames';
import { logo } from 'src/assets/themes/themes';
import { connect } from 'react-redux';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import styles from './Navbar.module.css';

// translating HOC
import { withTranslation } from 'react-i18next';
import { setShowAcademy, setLoadingGlobal } from 'src/redux/actions/actions.board';
import { getWorkspaces } from 'src/redux/actions/actions.workspaces';
import { urlParamsToObj } from 'src/Widgets/common/helpers';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';
import emmGlobe from 'src/assets/emm-circle-2407.png';

export class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.params = urlParamsToObj(window.location?.search, ['academy']);
        this.state = {
            isNavCollapsed: false
        };
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav = () => {
        this.setState((prevState) => ({ isNavCollapsed: !prevState.isNavCollapsed }));
    };

    renderWorkspaceLink(workspaceName, i) {
        let active = false;

        if (this.props.location.pathname.includes('workspaces')) {
            active = this.props.currentWorkspace === i;
        }

        const linkClasses = classNames(
            this.state.isNavCollapsed ? 'workspace-link-collapsed' : 'workspace-link',
            {
                // TODO maybe: use this again: 'animated', 'bounceIn' (but avoid bouncing on page load)
                'workspace-link-active': active,
                [this.props.theme.borderSecondary]: active
            }
        );

        return (
            <div
                key={i + '-container'}
                className={linkClasses}
                data-tut={`tour__workspace-${i + 1}`}
            >
                <span
                    className={
                        this.state.isNavCollapsed
                            ? 'remove-workspace-collapsed' +
                              (this.props.editable
                                  ? ' animated bounceIn'
                                  : ' remove-workspace-hidden')
                            : 'remove-workspace' +
                              (this.props.editable
                                  ? ' animated bounceIn'
                                  : ' remove-workspace-hidden')
                    }
                    onClick={() => this.props.removeWorkspace(i)}
                >
                    <FontAwesomeIcon
                        icon="times-circle"
                        size={this.state.isNavCollapsed ? 'xs' : '1x'}
                    />
                </span>

                <NavLink
                    key={i}
                    className={
                        this.state.isNavCollapsed
                            ? 'navbar-link-collapsed'
                            : 'navbar-link'
                    }
                    to={'/workspaces/' + (i + 1)}
                >
                    <div className={this.state.isNavCollapsed ? '' : "navLinkWithIconContainer"}>
                        <FontAwesomeIcon
                            icon={this.props.workspaces.allWorkspaces[i].icon}
                            size={this.state.isNavCollapsed ? '2x' : '1x'}
                        />
                        <div
                            className={
                                this.state.isNavCollapsed ? 'navLinkTextCollapsed' : ''
                            }
                        >
                            {/* {this.state.isNavCollapsed
                                ? workspaceName
                                      .split(' ')
                                      .map((word, index, arr) =>
                                          arr.length === 1 ? word.slice(0, 2) : word[0]
                                      )
                                      .join('')
                                : workspaceName || this.props.t('Unnamed')} */}
                                {this.state.isNavCollapsed ? null : workspaceName || this.props.t('Unnamed')}
                        </div>
                    </div>
                </NavLink>
            </div>
        );
    }

    render() {
        const { t, theme } = this.props;

        const navClasses = classNames(
            this.props.className,
            'nav',
            theme.backgroundPrimary
        );

        return (
            <React.Fragment>
                <nav
                    className={classNames(
                        this.props.className,
                        theme.backgroundPrimary,
                        navClasses,
                        this.state.isNavCollapsed ? 'nav-collapsed' : 'nav'
                    )}
                    data-tut="tour__navBar"
                >
                    <div
                        className={
                            this.state.isNavCollapsed
                                ? 'app-title-collapsed'
                                : 'app-title'
                        }
                    >
                        <img
                            src={this.state.isNavCollapsed ? emmGlobe : logo}
                            alt="eMedia Monitor"
                            className={
                                this.state.isNavCollapsed
                                    ? 'collapsedLogo'
                                    : 'nonCollapsedLogo'
                            }
                        />
                    </div>

                    {
                        // only render when editable
                        this.props.editable ? (
                            <Button
                                type="addWorkspace"
                                btnClass={
                                    this.state.isNavCollapsed
                                        ? 'add-workspace-collapsed'
                                        : 'add-workspace'
                                }
                                onClick={this.props.addWorkspace}
                            >
                                {t('Add Workspace')}
                            </Button>
                        ) : null
                    }

                    <div
                        className={
                            'workspace-links' +
                            (this.props.editable ? ' workspace-links-editable' : '')
                        }
                    >
                        <FontAwesomeIcon
                            style={{
                                cursor: 'pointer'
                            }}
                            title={
                                t('Collapse/Expand Navigation')
                            }
                            icon={
                                this.state.isNavCollapsed
                                    ? faAngleDoubleRight
                                    : faAngleDoubleLeft
                            }
                            onClick={this.toggleNav}
                            size={this.state.isNavCollapsed ? 'lg' : 'lg'}
                            className={
                                this.state.isNavCollapsed
                                    ? 'collapse-nav-icon-collapsed'
                                    : 'collapse-nav-icon'
                            }
                        />
                        {this.state.isNavCollapsed ? <hr
                            style={
                                this.state.isNavCollapsed
                                    ? { width: '2rem', display: 'flex' }
                                    : {}
                            }
                        />: null}
                        {
                            // render navlinks depending on existing workspaces
                            _.map(this.props.workspaces.names, (workspaceName, i) =>
                                this.renderWorkspaceLink(workspaceName, i)
                            )
                        }
                    </div>

                    {this.props.isDesktop && dynamicConfig.allowEditBoard ? (
                        <div
                            className={
                                this.state.isNavCollapsed
                                    ? styles.editBoardCollapsed
                                    : styles.editBoard
                            }
                        >
                            <hr
                                style={
                                    this.state.isNavCollapsed
                                        ? { width: '2rem', display: 'flex' }
                                        : {}
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: this.state.isNavCollapsed
                                        ? 'column'
                                        : 'row'
                                }}
                            >
                                {(this.props.me.isAcademyAdmin ||
                                    !this.props.board.showAcademy) && ( // in academy mode: show for 'admin' only
                                    <ToggleEditButton
                                        editable={this.props.editable}
                                        onClick={this.props.toggleBoardEdit}
                                        isNavCollapsed={this.state.isNavCollapsed}
                                    />
                                )}
                            </div>
                        </div>
                    ) : null}
                    {(this.props.accountSettings.data.academyEnabled ||
                        this.params.academy === '1') &&
                        dynamicConfig.allowAcademy && (
                            <div
                                className={
                                    this.state.isNavCollapsed
                                        ? styles.toggleAcademyCollapsed
                                        : styles.toggleAcademy
                                }
                            >
                                <Button
                                    type="secondary"
                                    btnClass={
                                        this.state.isNavCollapsed
                                            ? styles.academyButtonCollapsed
                                            : styles.academyButton
                                    }
                                    onClick={async () => {
                                        this.props.setLoadingGlobal(true);
                                        await this.props.setShowAcademy(
                                            !this.props.board.showAcademy
                                        );
                                        this.props.getWorkspaces();
                                    }}
                                >
                                    {this.props.board.showAcademy
                                        ? this.props.t('Go To Dart')
                                        : this.props.t('Go To Academy')}
                                </Button>
                            </div>
                        )}
                </nav>

                {/* 
                    only render when .display is set
                    and because of css @media query only when in portrait mode 

                    (invisible) div to toggle nav in portrait view
                */}
                {this.props.className ? (
                    <div
                        className="nav-away animated fadeIn"
                        onClick={this.props.toggleDisplayMenuPortrait}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    theme: state.theme,
    me: state.me,
    board: state.board,
    accountSettings: state.accountSettings
});
export default connect(mapStateToProps, {
    setShowAcademy,
    getWorkspaces,
    setLoadingGlobal
})(withTranslation()(Navbar));
