export const BROWSER_CHROME = 'Chrome';
export const BROWSER_FIREFOX = 'Firefox';
export const AVAILABLE_LANGUAGES = [
    'en',
];
export const USER_SETTINGS = {
    GENERAL_KEYS: {
        ACCOUNT_SETTINGS: 'accountSettings',
    }
}

export const ROUTES = {
    sign_up_email: '/sign-up/e-mail',
    sign_up_verify_email: '/sign-up/verify-e-mail',
    mef_pricing: '/pricing',
    mef_pricing_public: '/public/pricing',
    upgrade_plan: '/account/upgrade-plan',
}