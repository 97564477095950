import React from 'react';
import styles from './Schedules.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getWeekdays, replaceAt, timeIntToString, timeStringToInt } from '../common/helpers';
import _ from 'lodash';
import LabeledSwitch from '../common/basicElements/SwitchElements/LabeledSwitch';
import TimePicker from 'react-time-picker';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import SelectAsync from '../common/basicElements/SelectAsync/SelectAsync';
import TextInput from '../common/basicElements/TextInput/TextInput';
import {
    getSchedules,
    getTasks,
    deleteSchedule,
    postSchedule,
    putSchedule,
} from 'src/redux/actions/actions.schedules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import InfoBox from '../common/basicElements/InfoBox/InfoBox';
import { STATUS_SUCCEEDED } from 'src/redux/types/status';
import MySelect from '../common/basicElements/MySelect/MySelect';
import Popup from '../common/basicElements/Popup/Popup';

// TODO: adjust/integrate with actual api - as soon as it is available

class Schedules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            selectedScheduleId: undefined,
            selectedSchedule: undefined,
            edited: false,
            tasksToDelete: [],
            showFillFieldsMsg: false,
        };

        this.repeatUnitOptions = [
            { value: 'hours', label: this.props.t('hours'), min: 1, max: 23, default: 1 },
            { value: 'minutes', label: this.props.t('minutes'), min: 1, max: 59, default: 30 }
        ];

        this.onChange = this.onChange.bind(this);
        this.onAddSchedule = this.onAddSchedule.bind(this);
        this.save = this.save.bind(this);
        this.renderTaskDetails = this.renderTaskDetails.bind(this);
    }

    componentDidMount(){
        this.props.getSchedules();
        // this.onAddSchedule();
    }

    clearSelected(){
        this.setState({selectedSchedule: undefined, selectedScheduleId: undefined, edited: false})
    }

    componentDidUpdate(prevProps, prevState){
        const selectedSchedule = _.find(this.props.schedules.schedules,{id: this.state.selectedScheduleId});
        const prevSelectedSchedule = _.find(prevProps.schedules.schedules,{id: prevState.selectedScheduleId});

        // if(this.props.schedules.schedules.length > prevprops.schedules.schedules.length){}
        if(prevSelectedSchedule && !selectedSchedule){
            // clear selection from state if not available (thats the case if schedule gets deleted)
            
        } else if(
            this.state.selectedScheduleId !== undefined &&
            (
                this.state.selectedScheduleId !== prevState.selectedScheduleId ||
                !_.isEqual(selectedSchedule.tasks, prevSelectedSchedule.tasks)
            )
        ){  // set selected schedule as soon as tasks got fetched
            this.setState( {selectedSchedule: _.cloneDeep(selectedSchedule), msg:undefined} );
        }
    }

    onChange(){
        this.setState({edited: true});
    }

    onAddSchedule(){
        this.setState({
            selectedScheduleId: undefined,
            selectedSchedule:{
                title: undefined,
                tasks: [ this.createTask() ],
                // taskIds: [],
            },
            msg: undefined,
        });
    }

    createTask(time=0){
        return {
            weekdays: "-------",
            time,
        };
    }

    async save(){
        let s = _.cloneDeep(this.state.selectedSchedule);
        if(s.title){
            s.tasks = s.tasks.map(t=>({
                ...t,
                _edited: undefined,
            }));
            let selectedScheduleId;
            if(s.id){
                // TODO: improvement: keep track of updated values and update changed values only ?

                // s.tasks = s.tasks.reduce((sum,t)=>{ if(t._edited) sum.push({...t, _edited: undefined}); return sum },[]);
                await this.props.putSchedule(s.id,s, this.state.tasksToDelete);
                this.setState({tasksToDelete:[], edited: false});
            } else {
                selectedScheduleId = await this.props.postSchedule(s);
                this.setState({selectedScheduleId, edited: false});
            }
            this.props.getTasks(s.id !== undefined ? s.id : selectedScheduleId);
        } else {
            this.setState({ showFillFieldsMsg: true });
        }
    }

    async delete(schedule){
        this.setState({msg:undefined});
        if(schedule.id){
            const res = await this.props.deleteSchedule(schedule);  
            if(res.rc === 0) {
                this.clearSelected();
            } else if(res.rc === 4445){
                this.setState({
                    msg: <div>
                            {res.msg}:
                            <ul style={{margin: '0.3rem'}}>
                                {res.profileIDs 
                                    && res.profileIDs.map( (p)=>(
                                        <li>{p.title}</li>
                                    ) )
                                }
                            </ul>
                        </div>
                });
            }
        } else {
            this.clearSelected();
        }
    }

    renderTaskDetails(task, taskIndex, isOnly){
        const time = timeIntToString(task.time);
        const untilTime = timeIntToString(task.until);
        const repeatUnit = _.find(this.repeatUnitOptions, {value: task.repeatUnit});

        return(
            <div className={styles.details}>
                <div className={styles.weekdays}>
                    {_.map(getWeekdays(), (d, i) => {
                    return (
                    <LabeledSwitch
                            key={i}
                            checked={task.weekdays[i] === d.value}
                            onChange={()=>{
                                this.onChange();
                                const checked = task.weekdays[i] === d.value;
                                let scheduleUpdate = _.cloneDeep(this.state.selectedSchedule);
                                scheduleUpdate.tasks[taskIndex].weekdays = replaceAt(
                                    scheduleUpdate.tasks[taskIndex].weekdays,
                                    i,
                                    checked ? '-' : d.value
                                );
                                scheduleUpdate.tasks[taskIndex]._edited = true;
                                this.setState({selectedSchedule: scheduleUpdate});
                            }}
                        >
                            {d.text}
                        </LabeledSwitch> 
                    )
                    })}
                </div>
                <div className={styles.timeOptions}>
                    <span>{this.props.t('at')}</span>
                    <TimePicker
                        onChange={(value)=>{
                            this.onChange();
                            value = value !== null ? value : '00:00';
                            let s = _.cloneDeep(this.state.selectedSchedule);
                            s.tasks[taskIndex].time = timeStringToInt(value);
                            if(s.tasks[taskIndex].until && s.tasks[taskIndex].time > s.tasks[taskIndex].until){
                                // update 'until' as it should never be smaller than start time anyways
                                s.tasks[taskIndex].until = s.tasks[taskIndex].time;
                            }
                            this.setState({selectedSchedule:s});
                        }}
                        format="HH:mm"
                        value={time}
                        clearIcon={null}
                        disableClock={true}
                    />
                    <LabeledSwitch
                        className={styles.repeat}
                        // key={i}
                        checked={task.until !== undefined ? true : false}
                        onChange={()=>{
                            this.onChange();
                            let update = _.cloneDeep(this.state.selectedSchedule);
                            if(task.until !== undefined){
                                update.tasks[taskIndex] = {...update.tasks[taskIndex],
                                    repeatUnit: undefined,
                                    repeatNumber: undefined,
                                    until: undefined,
                                };
                            } else {
                                update.tasks[taskIndex] = {...update.tasks[taskIndex],
                                    repeatUnit: this.repeatUnitOptions[0].value,
                                    repeatNumber: this.repeatUnitOptions[0].default,
                                    until: task.time,
                                };
                            }

                            this.setState({selectedSchedule: update});

                            // legacy: duplicating tasks when clicking "repeat hourly"
                            // let update = _.cloneDeep(this.state.selectedSchedule);
                            // while (
                            //     update.tasks.length < 24
                            //     && (update.tasks[0].hour + update.tasks.length) < 24
                            // ){
                            //     let newTask = _.cloneDeep(update.tasks[0]);
                            //     newTask.id = undefined;
                            //     newTask.hour = update.tasks[0].hour + update.tasks.length;
                            //     update.tasks.push(newTask);
                            // }
                            // this.setState({selectedSchedule: update});
                        }}
                    >
                        {this.props.t('Repeat')}
                    </LabeledSwitch> 
                    {task.until !== undefined &&
                        <>
                            <span>{this.props.t('every')}</span>
                            <TextInput
                                placeholder=' '
                                inputType="number"
                                max={repeatUnit.max}
                                min={repeatUnit.min}
                                className={styles.repeatNumber}
                                value={task.repeatNumber}
                                onChange={(e)=>{
                                    let update = _.cloneDeep(this.state.selectedSchedule);
                                    update.tasks[taskIndex].repeatNumber = parseInt(e.target.value);
                                    this.setState({selectedSchedule: update});
                            }} />
                            <MySelect
                                value={repeatUnit}
                                options={this.repeatUnitOptions}
                                onChange={(e) => {
                                    let update = _.cloneDeep(this.state.selectedSchedule);
                                    update.tasks[taskIndex].repeatUnit = e.value;
                                    if(task.repeatNumber > e.max){ update.tasks[taskIndex].repeatNumber = e.max; }
                                    if (task.repeatNumber < e.min) { update.tasks[taskIndex].repeatNumber = e.min; }
                                    console.log(update)
                                    this.setState({selectedSchedule: update});
                                }}
                                className={styles.repeatUnit}
                            />
                            {/* <SelectAsync
                                // onMenuClose={() => { debugger; }}
                                menuPosition="absolute"
                                options={this.repeatUnitOptions}
                                value={repeatUnit}
                                onChange={(e) => {
                                    console.log(e.max,e.min)
                                    let update = _.cloneDeep(this.state.selectedSchedule);
                                    update.tasks[taskIndex].repeatUnit = e.value;
                                    if(task.repeatNumber > e.max){ update.tasks[taskIndex].repeatNumber = e.max; }
                                    if(task.repeatNumber < e.min){ update.tasks[taskIndex].repeatNumber = e.min; }
                                    this.setState({selectedSchedule: update});
                                }}
                                className={styles.repeatUnit}
                            /> */}
                            <span>{this.props.t('until')}</span>
                            <TimePicker
                                minTime={time}
                                onChange={(value)=>{
                                    value = value !== null ? value : '00:00';
                                    let s = _.cloneDeep(this.state.selectedSchedule);
                                    s.tasks[taskIndex].until = timeStringToInt(value);
                                    this.setState({selectedSchedule:s});
                                }}
                                format="HH:mm"
                                value={untilTime}
                                clearIcon={null}
                                disableClock={true}
                            />
                        </>
                    }
                </div>
            </div>
        )
    }

    renderTask(task, taskIndex, isOnly, isDisabled){
        return(
            <div
                key={taskIndex} 
                className={[
                    styles.task,
                    isDisabled && styles.disabled,
                ].join(' ')}
                onClick={()=>{ if(isDisabled) this.addTask(); }}
                title={isDisabled ? this.props.t('Click to add a new entry') : undefined}
            >
                {this.renderTaskDetails(task, taskIndex, isOnly)}
                <div className={styles.options}>
                    {!isOnly && <Button type="primary" onClick={!isDisabled ?
                        ()=>{
                            let update = _.cloneDeep(this.state.selectedSchedule);
                            let tasksToDelete = _.cloneDeep(this.state.tasksToDelete);
                            if(task.id){ tasksToDelete.push(task.id); };
                            update.tasks.splice(taskIndex, 1);
                            this.setState({selectedSchedule: update, edited: true, tasksToDelete});
                        } : undefined}>
                            <FontAwesomeIcon icon={faTrash} 
                                title={!isDisabled ? this.props.t('Delete') : undefined}
                            />
                    </Button>}
                </div>
            </div>
        )
    }

    onLeaveModified = (action) =>{
        let confirmed = true;
        if(this.state.edited){ confirmed = window.confirm(`${this.props.t('Unsaved changes will be lost')}. ${this.props.t('Do you want to continue')}?`); }
        if(confirmed){
            this.setState({edited:false}, action);
        }
    }

    addTask(){
        let update = _.cloneDeep(this.state.selectedSchedule);
        update.tasks.push(this.createTask());
        this.setState({selectedSchedule: update});
    }

    renderSchedule(schedule){
        return (
            <div className={styles.currentSchedule}>
                { this.state.msg &&
                    <InfoBox
                        type='danger'
                        text={this.state.msg}
                    />
                }
                    <div>
                        {this.props.t('Schedule title')}: 
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <div style={{width:'300px', maxWidth:'calc(100% - 20rem)',marginBlockStart: '0.3rem'}}>
                            <TextInput
                                className={styles.input}
                                // inputRef={(r)=>{this.titleRef = r;}}
                                value={this.state.selectedSchedule.title}
                                onChange={(e)=>{
                                    this.onChange();
                                    this.setState({selectedSchedule: {...schedule, title:e.target.value}})
                                }}
                                placeholder={' '}
                            />
                            </div>
                            { this.props.me &&
                                <div className={styles.timezone} title={this.props.t('Navigate to account settings to change your time zone')}>
                                    <b>{this.props.t('Timezone')}: </b>
                                    {this.props.me.timezone}
                                    {/* <a href="#/account/settings" target="_blank">
                                        <Button
                                            // style={{padding:'0 0.3rem'}}
                                            type="primary"
                                            title={this.props.t('Click here to open user settings to change your time zone')}
                                        >
                                                <FontAwesomeIcon icon="pen"/>
                                        </Button>
                                    </a> */}
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.tasks}>
                        {_.map(schedule.tasks,(task, idx)=>{return this.renderTask(task, idx, schedule.tasks.length === 1)})}
                        { schedule.tasks.length === 1 &&
                            this.renderTask(this.createTask(), -1, false, true)
                        }
                    </div>
                    <div className={styles.options}>
                        <div>
                            <Button
                                type="primary"
                                onClick={()=>{ this.addTask(); }}
                            >
                                {`+ ${this.props.t('Add')}`}
                            </Button>
                        </div>
                        <div style={{marginBlockStart:'1rem'}}>
                            <Button
                                onClick={this.save}
                                disabled={!this.state.edited}
                                type="success"
                                // title={this.props.t('')}
                            >
                                {this.props.t('Save')}
                            </Button>
                            <Button type="danger" onClick={()=>this.delete(schedule)}>{this.props.t('Delete')}</Button>
                        </div>
                    </div>
                </div>)
    }

    render() {
        const schedules = _.map(this.props.schedules.schedules,(s)=>({ label: s.title, value: s.id}));
        const {t} = this.props;
        let schedule = _.find(this.props.schedules.schedules, {id: this.state.selectedScheduleId})
        schedule = schedule ? {label:schedule.title,value:schedule.id} : null;
        // if(this.titleRef) this.titleRef.focus();

        return(
            <div className={styles.schedule}>
                {this.state.showFillFieldsMsg &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={() => { this.setState({ showFillFieldsMsg: undefined }); }}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <p>{`${this.props.t('Please fill out all required fields')}: ${this.props.t('Schedule title')}`}</p>
                        </div>
                    </Popup>
                }
                <div style={{display:'flex'}}>
                    <div className={styles.select}>
                        <SelectAsync
                            loading={this.props.schedules.status === STATUS_SUCCEEDED ? false : true}
                            spinnerText={t("Loading Schedules")}
                            placeholder={t('Select existing schedule')}
                            options={schedules}
                            value={schedule}
                            onChange={(val) => {
                                this.onLeaveModified(()=>{
                                    const id = val ? val.value : undefined;
                                    this.setState({ 
                                        selectedScheduleId: id
                                    });
                                    if(id !== undefined) this.props.getTasks(id);
                                });
                            }}
                            isClearable={false}
                            // className={[styles.select, ].join(' ')}
                        />
                    </div>
                    <Button
                        disabled={this.state.selectedSchedule && this.state.selectedSchedule.id === undefined}
                        onClick={()=>{
                            this.onLeaveModified(this.onAddSchedule);
                        }}
                        type="secondary"
                    >
                        {`+ ${t('Add schedule')}`}
                    </Button>
                </div>
                {this.state.selectedSchedule && this.state.selectedSchedule.tasks &&
                    this.renderSchedule(this.state.selectedSchedule)
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    schedules: state.schedules,
    me: state.me,
});
export default connect(mapStateToProps,{ 
    getSchedules, 
    getTasks, 
    deleteSchedule,
    postSchedule,
    putSchedule,
 })(withTranslation()(Schedules));