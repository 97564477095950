import React, { useState } from 'react';
import useFindChannelType from './useChannelTypeFind';
import { euCountries } from 'src/Widgets/common/CountryFlag/countryFlags';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';

const useClipsFilters = ({ profileGroupContent, setClipDetail, setFetchedItems, processedIndices }: MentionsVisualizerTypes.UseClipsFiltersProps) => {
    const { findChannelType } = useFindChannelType();

    const [searchChannelsInput, setSearchChannelsInput] = useState('');
    const [filteredChannelIcons, setFilteredChannelIcons] = useState<any>([]);
    const [filteredDataToDisplay, setFilteredDataToDisplay] = useState<any>(profileGroupContent.clips);
    const [listKey, setListKey] = useState(Date.now());
    const [selectedLanguages, setSelectedLanguages] = useState<{ [key: string]: string[] }>({});
    const [activeFilter, setActiveFilter] = useState<MentionsVisualizerTypes.ActiveFilterTypes>({
        channels: [],
        countries: [],
        foldEnabled: true,
        profile: null,
        type: null,
        sentiment: null
    });

    const resetFetchedItemsAndProcessedIndices = () => {
        setFetchedItems(new Set());
        setClipDetail({});
        processedIndices.clear();
        setListKey(Date.now());
    };

    const uniqueChannels = Array.from(new Set(profileGroupContent.clips.map((clip) => clip.channelName))).map((channelName) => {
        return profileGroupContent.clips.find((clip) => clip.channelName === channelName);
    });

    const sortedUniqueChannels = [...uniqueChannels].sort((a: any, b: any) => {
        if (a.channelName < b.channelName) {
            return -1;
        }
        if (a.channelName > b.channelName) {
            return 1;
        }
        return 0;
    });

    const countrySet = new Set();

    const getUniqueCountries = () => {
        const uniqueCountries = profileGroupContent.clips.filter((clip) => {
            const isActiveProfile = activeFilter.profile ? clip.profileId === activeFilter.profile : true;
            const isActiveType = activeFilter.type ? findChannelType(clip.channelNumber) === activeFilter.type : true;
            return isActiveProfile && isActiveType;
        });

        const uniqueCountriesFiltered = uniqueCountries.filter((clip) => {
            const isNewCountry = !countrySet.has(clip.cc);
            countrySet.add(clip.cc);
            return isNewCountry;
        });

        return uniqueCountriesFiltered.map((clip) => clip);
    };

    const getLanguagesByCountry = (countryCode: string) => {
        return Array.from(new Set(profileGroupContent.clips.filter((clip) => clip.cc === countryCode).map((clip) => clip.language)));
    };

    const sortedUniqueCountries = getUniqueCountries().sort((a, b) => {
        if (a.cc < b.cc) {
            return -1;
        }
        if (a.cc > b.cc) {
            return 1;
        }
        return 0;
    });

    const filterData = () => {
        let data = profileGroupContent.clips;

        if (activeFilter.profile) {
            data = data.filter((clip) => clip.profileId === activeFilter.profile);
        }

        if (activeFilter.channels.length > 0) {
            data = data.filter((clip) => activeFilter.channels.includes(clip.channelName));
        }

        if (activeFilter.countries.length > 0) {
            data = data.filter((clip) => activeFilter.countries.includes(clip.cc));
        }

        if (activeFilter.sentiment) {
            data = data.filter((clip) => clip.sentiment === activeFilter.sentiment);
        }

        Object.keys(selectedLanguages).forEach((country) => {
            if (selectedLanguages[country].length > 0) {
                data = data.filter((clip) => clip.cc !== country || selectedLanguages[country].includes(clip.language));
            }
        });

        if (activeFilter.type) {
            data = data.filter((clip) => findChannelType(clip.channelNumber) === activeFilter.type);
        }

        if (activeFilter.foldEnabled) {
            const duplicateClips: MentionsVisualizerTypes.Clip[] = data.filter((clip) => clip.dupType !== null) || [];
            data = data.filter((clip) => clip.dupType === null);
            data = data.concat(
                Array.from(new Set(duplicateClips.map((clip) => clip.dupType)))
                    .map((dupType) => duplicateClips.filter((clip) => clip.dupType === dupType)[0])
                    .filter((clip) => clip.dupType !== undefined || clip.dupType !== null)
            );
        }

        setFilteredDataToDisplay(data);
        resetFetchedItemsAndProcessedIndices();
    };

    const handleProfileFilter = (profileId: number) => {
        setActiveFilter((prev) => ({
            ...prev,
            profile: prev.profile === profileId ? null : profileId,
            channels: [],
            countries: [],
            type: null
        }));
    };

    const handleChannelFilter = (channel: string) => {
        setActiveFilter((prev) => {
            const selectedChannels = [...prev.channels];
            const channelIndex = selectedChannels.indexOf(channel);

            if (channelIndex > -1) {
                selectedChannels.splice(channelIndex, 1);
            } else {
                selectedChannels.push(channel);
            }

            return {
                ...prev,
                channels: selectedChannels,
                countries: []
            };
        });
    };

    const handleCountryFilter = (country: string) => {
        setSearchChannelsInput('');
        setActiveFilter((prev) => {
            const selectedCountries = [...prev.countries];
            if (country === 'EU') {
                return {
                    ...prev,
                    channels: [],
                    countries: selectedCountries.length === euCountries.length ? [] : euCountries
                };
            }
            const countryIndex = selectedCountries.indexOf(country);
            if (countryIndex !== -1) {
                selectedCountries.splice(countryIndex, 1);
            } else {
                selectedCountries.push(country);
            }
            return {
                ...prev,
                channels: [],
                countries: selectedCountries
            };
        });
    };

    const handleLanguageFilter = (country: string, language: string) => {
        setSelectedLanguages((prev) => {
            const newSelectedLanguages = { ...prev };
            if (!newSelectedLanguages[country]) {
                newSelectedLanguages[country] = [];
            }
            const languageIndex = newSelectedLanguages[country].indexOf(language);

            if (languageIndex > -1) {
                newSelectedLanguages[country].splice(languageIndex, 1);
            } else {
                newSelectedLanguages[country].push(language);
            }

            return newSelectedLanguages;
        });
    };

    const handleSentimentFilter = (sentiment: string) => {
        setActiveFilter((prev) => ({
            ...prev,
            sentiment: prev.sentiment === sentiment ? null : sentiment
        }));
    }

    const hasSentiment = (sentiment: string) => {
        return profileGroupContent.clips.some((clip) => clip.sentiment === sentiment);
    };

    const handleSearchChannels = (channelName: string) => {
        
        const allFiltersAppliedChannels = profileGroupContent.clips.filter((clip) => {
            const isActiveProfile = activeFilter.profile ? clip.profileId === activeFilter.profile : true;
            const isActiveType = activeFilter.type ? findChannelType(clip.channelNumber) === activeFilter.type : true;
            const isActiveCountry = activeFilter.countries.length > 0 ? activeFilter.countries.includes(clip.cc) : true;
            return isActiveProfile && isActiveType && isActiveCountry;
        }
        );

        const filteredChannels = Array.from(new Set(allFiltersAppliedChannels.map((clip) => clip.channelName))).map((channelName) => {
            return allFiltersAppliedChannels.find((clip) => clip.channelName === channelName);
        }
        ).sort((a: any, b: any) => {
            if (a.channelName < b.channelName) {
                return -1;
            }
            if (a.channelName > b.channelName) {
                return 1;
            }
            return 0;
        });


        if (channelName === '') {
            setFilteredChannelIcons(filteredChannels);
        } else {
            const result = filteredChannels.filter((channel: MentionsVisualizerTypes.Clip | undefined) =>
                channel?.channelName.toLowerCase().includes(channelName.toLowerCase())
            );
            setFilteredChannelIcons(result);
        }
    };

    const handleTypeFilter = (type: string) => {
        const isProfileSelected = activeFilter.profile !== null;
        const data = isProfileSelected
            ? profileGroupContent.clips.filter(clip => clip.profileId === activeFilter.profile)
            : profileGroupContent.clips;

        const channelsWithType = data.filter((clip) => findChannelType(clip.channelNumber) === type).map((clip) => clip.channelName);

        const filteredChannels = sortedUniqueChannels.filter((channel: MentionsVisualizerTypes.Clip | undefined) =>
            channel && channelsWithType.includes(channel.channelName)
        );

        if (activeFilter.type === type) {
            setActiveFilter((prev) => ({
                ...prev,
                type: null,
                countries: []
            }));

            if (isProfileSelected) {
                setFilteredChannelIcons(
                    sortedUniqueChannels.filter((channel: MentionsVisualizerTypes.Clip | undefined) =>
                        channel && profileGroupContent.clips.some((clip) => clip.profileId === activeFilter.profile && clip.channelName === channel.channelName)
                    ))
            } else {
                setFilteredChannelIcons(sortedUniqueChannels);
            }

            return;
        }

        setActiveFilter((prev) => ({
            ...prev,
            type,
            countries: []
        }));
        setSearchChannelsInput('')
        setFilteredChannelIcons(filteredChannels);
    };

    const hasTypeInGroup = (type: string) => {
        return profileGroupContent.clips.some((clip) => findChannelType(clip.channelNumber) === type);
    };

    const hasTypeInProfile = (profileId: number, type: string) => {
        return profileGroupContent.clips.some((clip) => clip.profileId === profileId && findChannelType(clip.channelNumber) === type);
    };

    return {
        searchChannelsInput,
        setSearchChannelsInput,
        setFilteredChannelIcons,
        setActiveFilter,
        filteredChannelIcons,
        filteredDataToDisplay,
        listKey,
        activeFilter,
        handleProfileFilter,
        handleChannelFilter,
        handleCountryFilter,
        handleSearchChannels,
        handleSentimentFilter,
        handleTypeFilter,
        handleLanguageFilter,
        hasSentiment,
        filterData,
        sortedUniqueChannels,
        sortedUniqueCountries,
        getLanguagesByCountry,
        selectedLanguages,
        hasTypeInGroup,
        hasTypeInProfile
    };
};

export default useClipsFilters;
