import React, { ChangeEventHandler } from 'react';
import styles from './OptionSwitch.module.scss';

const OptionSwitch = (
    {
        option1,
        option2,
        onChange,
        checkedValue
    }: {
        option1: string;
        option2: string;
        onChange: ChangeEventHandler<HTMLInputElement>;
        checkedValue: boolean;
    }
) => {
    
    return (
        <>
            <input
                className={styles.switchCheckbox}
                type="checkbox"
                id="switch"
                onChange={onChange}
                checked={checkedValue}
            ></input>
            <label className={styles.toggleSwitchLabelContainer} htmlFor="switch">
                <div className={styles.toggleSwitch}></div>
                <div className={`${styles.toggleSwitchOption} ${styles.option1}`}>
                    {option1}
                </div>
                <div className={`${styles.toggleSwitchOption} ${styles.option2}`}>
                    {option2}
                </div>
            </label>
        </>
    );
};

export default OptionSwitch;
