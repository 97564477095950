import GenericCalls from "./genericCalls";

class APIOAuth2{
    static login(reAuthenticationUser, authProvider) {
        // initiate oauth2 authentication, passing current search parameters (currently e.g. "?academy=1" to allow backend to append when redirecting back to v3)
        const redirect = encodeURIComponent(`/v3/${window.location.search}#/oauth2/${authProvider}/?`);
        // const redirect = encodeURIComponent(`/v3/#/oauth2/google/${window.location.search}`);
        const popup = {
            width:500,
            height: 591
        }

        let url = `${window.location.origin}/api/oauth2/${authProvider}?redir=${redirect}`;
        
        if(reAuthenticationUser){
            // pass userid which was used in previous login to allow backend to only provide new session if same user is used again
            // reason: we preserve UI state "onReAuthentication", so we do not want to mix users
            url += `&userid=${reAuthenticationUser}`;
        }

        // open in new browser popup window
        window.open(
            url,
            undefined,
            `popup=true,
            width=${popup.width},
            height=${popup.height},
            top=${(window.outerHeight - popup.height)/2},
            left=${(window.outerWidth - popup.width)/2},
            `
        );
    }

    static async register(type, id, email, emailLang, timezone, authProvider) {
        // type = api.trial || v3.trial || bi.trial
        // create new Dart user - which has previously been authenticated using (google) oauth2
        return await GenericCalls.post(`/oauth2/register/using/${authProvider}/for/${type}`,{
            emailLang,
            tz: timezone,
            email,
            id,
        });
    }
}

export default APIOAuth2;