import React, { memo, useState } from 'react';
import styles from './ClipColumn.module.scss';
//@ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
//@ts-ignore
import { FixedSizeList as List, areEqual } from 'react-window';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { ReactWindowProps } from 'src/Widgets/common/MentionResults/MentionResults.types';
import useChannelIcons from 'src/hooks/useChannelIcons';
import APIMentions from 'src/API/APIMentions';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import Skeleton from '../../Skeleton/Skeleton';
import ClipCard from '../../ClipCard/ClipCard';
import { useTranslation } from 'react-i18next';

import Button from 'src/Widgets/common/basicElements/Button/Button';
import APIClips from 'src/API/APIClips';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faList } from '@fortawesome/free-solid-svg-icons';

const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const ClipColumn = ({
    listKey,
    filteredDataToDisplay,
    isSearch,
    processedIndices,
    fetchedItems,
    setFetchedItems,
    clipDetail,
    setClipDetail,
    isEdited,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setBackFillSelectionVisible
}: MentionsVisualizerTypes.ClipColumnProps) => {
    const ongoingRequests: Set<number> = new Set();

    const isMobile = useIsScreenSize(1700);
    const { findChannelIconsById } = useChannelIcons();
    const { t } = useTranslation();

    const [selectedCardElements, setSelectedCardElements] = useState<any[]>([]);
    const [movedItemsMessage, setMovedItemsMessage] = useState<string>('');

    //fetch notification details
    const fetchNotificationDetails = async (notifID: number) => {
        if (
            !clipDetail[notifID] &&
            !fetchedItems.has(notifID) &&
            notifID !== undefined &&
            !ongoingRequests.has(notifID)
        ) {
            ongoingRequests.add(notifID);
            try {
                const res = await APIMentions.getNotificationResults(
                    isSearch ? notifID + 't' : notifID
                );
                setClipDetail((prevData: MentionsVisualizerTypes.ClipDetailType) => ({
                    ...prevData,
                    [notifID]: res.data
                }));
                setFetchedItems((prevItems: Set<number>) => {
                    const newItems = new Set(prevItems);
                    newItems.add(notifID);
                    return newItems;
                });
            } finally {
                ongoingRequests.delete(notifID);
            }
        }
    };

    const handleSelectAllCheckboxes = () => {
        if (selectedCardElements.length === filteredDataToDisplay.length) {
            setSelectedCardElements([]);
        } else {
            setSelectedCardElements(filteredDataToDisplay);
        }
    };

    //fetch notification details when scrolling
    const handleItemsRendered = debounce(
        ({ visibleStartIndex, visibleStopIndex }: any) => {
            const notifIDsToFetch = [];
            for (let index = visibleStartIndex; index <= visibleStopIndex; index++) {
                if (
                    !fetchedItems.has(filteredDataToDisplay[index].notifID) &&
                    !processedIndices.has(index)
                ) {
                    processedIndices.add(index);
                    notifIDsToFetch.push(filteredDataToDisplay[index]?.notifID);
                }
            }

            notifIDsToFetch.forEach((notifID) => {
                const validNotifID: string | number = notifID;
                fetchNotificationDetails(validNotifID as any);
            });
        },
        300
    );

    //keyframe row component - react window
    const KeyframeRow = memo(({ index, style }: ReactWindowProps) => {
        const clip = filteredDataToDisplay[index];
        const clipDetailData = clipDetail[clip.notifID];
        if (!clipDetailData) {
            return (
                <div style={style} key={clip.channelid}>
                    <Skeleton width="100%" height="120px" isClipCard />
                </div>
            );
        }
        return (
            <ClipCard
                clip={clip}
                clipDetailData={{
                    ...clipDetailData,
                    icon: findChannelIconsById(clip.channelNumber)
                }}
                style={style}
                isEdited={isEdited}
                activeFilter={activeFilter}
                isMentionFindrApp={isMentionFindrApp}
                backFillSelectionVisible={backFillSelectionVisible}
                setSelectedCardElements={setSelectedCardElements}
                selectedCardElements={selectedCardElements}
            />
        );
    }, areEqual);

    if (filteredDataToDisplay.length === 0) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                {t('Selected channels not in')}
                {activeFilter.type}
            </div>
        );
    }

    const backFillResultsToNotifications = async () => {
        //if the selected clips are from search query group, make them permanent
        if (isSearch) {
            const notifIDs = selectedCardElements.map(
                (item) => item.notifID || item.notifid
            );
            const res = await APIClips.makeClipsPermanent(
                filteredDataToDisplay[0].profileID,
                notifIDs
            );

            const notMovedItems = selectedCardElements.length - res.data.moved;
            if (res.data.moved && res.rc === 0) {
                setMovedItemsMessage(
                    notMovedItems > 0
                        ? `${notMovedItems} ${t('clips already moved')}. ` +
                              `${res.data.moved} ${t('clips moved to notifications')}`
                        : `${res.data.moved} ${t('clips moved to notifications')}`
                );
            } else if (res.data.moved === 0) {
                setMovedItemsMessage(
                    `${t('No clips moved to notifications')}, ${t('clips already moved')}`
                );
            } else {
                setMovedItemsMessage('');
            }

            setTimeout(() => {
                res.data.moved > 0 && setSelectedCardElements([]);
                setMovedItemsMessage('');
                res.data.moved > 0 && setBackFillSelectionVisible(false);
            }, 2000);
        }
    };

    return (
        <div
            className={
                backFillSelectionVisible
                    ? styles.clipsContentContainerBackFill
                    : styles.clipsContentContainer
            }
            data-testid="keyframe-row"
        >
            {backFillSelectionVisible && (
                <div className={styles.backFillSelectContainer}>
                    <div
                        onClick={handleSelectAllCheckboxes}
                        className={styles.backFillSelectAll}
                    >
                        <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={
                                selectedCardElements.length ===
                                filteredDataToDisplay.length
                            }
                            onChange={(e) => e.stopPropagation()}
                        />
                        <FontAwesomeIcon
                            icon={faCheckDouble}
                            className={styles.selectAllIcon}
                            title={t('Select all')}
                        />
                        <div
                            className={
                                selectedCardElements.length !== 0
                                    ? styles.selectedCount
                                    : styles.selectedCountNotAllSelected
                            }
                        >
                            {selectedCardElements.length}/{filteredDataToDisplay.length}{' '}
                            {t('Selected')}
                        </div>
                    </div>
                    {selectedCardElements.length > 0 ? (
                        <div className={styles.backFillButtons}>
                            {movedItemsMessage ? (
                                <span className={styles.movedItemsMessage}>
                                    {movedItemsMessage}
                                </span>
                            ) : (
                                <Button
                                    type={'secondary'}
                                    btnClass={styles.backFillButton}
                                    disabled={selectedCardElements.length === 0}
                                    onClick={backFillResultsToNotifications}
                                >
                                    {t('Backfill')}
                                </Button>
                            )}
                            <Button
                                type={'danger'}
                                btnClass={styles.backFillButton}
                                disabled={selectedCardElements.length === 0}
                                onClick={() => {
                                    setSelectedCardElements([]);
                                    setBackFillSelectionVisible(false);
                                }}
                            >
                                {'Cancel'}
                            </Button>
                        </div>
                    ) : (
                        <span className={styles.backFillMessage}>
                            {t('Please select clips to backfill')}
                        </span>
                    )}
                </div>
            )}
            <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                    <List
                        key={listKey}
                        height={height}
                        itemCount={filteredDataToDisplay.length}
                        itemSize={isMobile ? 250 : 140}
                        width={width}
                        onItemsRendered={handleItemsRendered}
                    >
                        {KeyframeRow}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default ClipColumn;
