import React from 'react';
import { useAppSelector } from 'src/redux/hooks';

const useFindChannelType = () => {
    const channels = useAppSelector((state: any) => state.channelReducers.data);

    const findChannelType = (channelId: number) => {
        const channel = channels.find((channel: any) => channel.id === channelId);
        if (channel) {
            if (channel.type === 'TV') {
                return 'tv';
            }
            if (channel.type === 'FM Radio' || channel.type === 'AM Radio') {
                return 'radio';
            }
            if (channel.type === 'Web Text' || channel.type === 'Press') {
                return 'web';
            }
        }
    };

    return { findChannelType }
}

export default useFindChannelType;
