import React, { Component } from 'react';
import GroupCards from './GroupCards';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { trackEvent } from '../helpers';

/**
 * gets api calls
 * gets group building function
 * manages basically everything
 *
 * - buildGroupState - function that builds normalized group from api response
 * - async createGroup - if no func given, hides add button
 * - async updateGroup
 * - async deleteGroup
 * - async getGroups
 * - async getGroupSearch - if no func given, hides searchbar
 *
 * - detailView - component to be rendered when item is clicked
 *      (takes id and goBack prop)
 * - createView - component to be rendered when item-add-button is clicked
 *
 * - useGravatar (default: false): if Gravatar should be used for icons/avatars
 *      (takes item.title for gravatar email)
 *
 * - uneditable (default: false): if groupdetails should be editable
 * - editItemIcon (default: false): if alt icon should be shown for items action button (for semantics)
 * - onDrop: function that gets executed when dropping files. If provided an area is generated where
 *       files can be dropped. This also sets the behaviour of the "plus" button (let filepicker dialog appear)
 * - createItem: api call to add an item to a group card
 */
class GroupCardsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: undefined,
            fetchedGroups: [],
            searchTerm: this.props.searchTerm || '',
            detailView: undefined,
            createView: undefined,
            groupView: undefined,
            secondaryDetailView: undefined,
            mode: undefined,
            selectedGroup: undefined,
            selectedGroupTitle: undefined
        };

        this.syncGroups = this.syncGroups.bind(this);
        this.showView = this.showView.bind(this);
        this.secondaryItemsClick = this.secondaryItemsClick.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.changeGroupTitle = this.changeGroupTitle.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.search = this.search.bind(this);
        this.onGoBack = this.onGoBack.bind(this);
        this.toggleMode = this.toggleMode.bind(this);
        this.openGroup = this.openGroup.bind(this);
    }

    async syncGroups() {
        const groups = this.props.getGroups ? (await this.props.getGroups()).data : this.props.groups;
        if (!groups || groups.status) return;

        this.allGroups = this.props.buildGroupState(
            groups,
            (id, data) => this.showView('detailView', id, data),
            this.secondaryItemsClick
        );

        this.setState({
            groups: this.allGroups,
        });
    }

    componentDidMount() {
        this.syncGroups();

        if (this.state.searchTerm.length > 0) {
            this.search(this.searchTerm);
        }

        if (this.props.listView) {
            this.setState({mode: 'list'})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.groups, this.props.groups)) {
            this.componentDidMount();
        }
    }

    showView(view, id, data) {
        let output = {};
        output[view] = id;
        output[view+'_data'] = data;

        this.setState(output);
    }

    // currently only for channelGroups in channelGroups
    secondaryItemsClick(secItem) {
        this.showView('secondaryDetailView', secItem);
    }

    changeGroupTitle(id, value) {
        // TODO: check return value
        if (this.props.reduxUpdateGroup) {
            this.props.reduxUpdateGroup(id, { title: value });
        } else {
            const response = this.props.updateGroup(id, { title: value });

            if (!response || response.status) return;

            this.setState(state => {
                _.find(state.groups, { id: id }).title = value;
                return { groups: state.groups };
            });
        }
    }

    async deleteGroup(id) {
        if (this.props.reduxDeleteGroup) {
            this.props.reduxDeleteGroup(id);
        } else {
            const response = await this.props.deleteGroup(id);

            // TODO: check return id
            if (!response || response.status) return;

            this.setState(state => {
                const groups = _.filter(state.groups, g => g.id !== id);
                return { groups };
            });
        }
    }

    async addGroup(title) {
        if(!this.props.createGroup && !this.props.reduxCreateGroup) return;
        
        if (this.props.reduxCreateGroup) {
            this.props.reduxCreateGroup({title});
        } else {
            const response = (await this.props.createGroup({ title }));

            if (!response || response.status) return;

            this.syncGroups();
        }
    }

    async search(searchTerm) {
        if (searchTerm.length < 3) {
            this.setState({ groups: this.allGroups });
        } else {
            this.debouncedSearch(searchTerm);
        }

        this.setState({ searchTerm });
    }

    async sendSearch(searchTerm) {
        if(!this.props.getGroupSearch) return;
            
        const found = await this.props.getGroupSearch(searchTerm, _.cloneDeep(this.state.groups));
        
        if (!found || !found.data || found.status) return;

        let groups;
        if (found.fetched !== false) {
            groups = this.props.buildGroupState(
                found.data,
                (id, data) => this.showView('detailView', id, data),
                this.secondaryClick
            );
        } else {
            // if found.fetched is set to false, we asume that props.getGroupSearch uses provided state.groups and therefore
            // props.buildGroupState should not be called again
            groups = found.data;
        }

        this.setState({ groups });
    }

    debouncedSearch = _.debounce(val => this.sendSearch(val), 250, {
        maxWait: 500
    });

    async addItemToState(item, groupId) {             
        const add = await this.props.createItem(item);
        if (!add || add.status) return;

        item.id = add.id;
        item.onClick = () => { this.showView('detailView', item.id) };
        this.setState(state => {                      
            _.find(state.groups, { id: groupId }).items.push(item);
            return { groups: state.groups };
        })        
    }

    async onGoBack(shouldGetGroup) {
        const eventGroup = this.props.trackingName ? `${this.props.trackingName}` : 'groupCardDetailView';
        let data = {};
        if (this.state.detailView) {
            data.id = this.state.detailView;
        }
        trackEvent(this.props.matomo, eventGroup, 'goBack', data);
        this.syncGroups();
        let group;
        if (
            this.props.getGroup
            && shouldGetGroup !== false // do not fetch in some cases - e.g. if "current group" was deleted
        ) {
            group = await this.props.getGroup(
                this.state.selectedGroup, id => this.showView('detailView', id)
            );
        }
        this.setState((s) => {
            if (group) s.fetchedGroups[this.state.selectedGroup] = group;
            return {
                ...s,
                detailView: undefined,
                createView: undefined,
                groupView: undefined,
                secondaryDetailView: undefined,
        }
        })
    }

    async openGroup(id, title) {
        // TODO; remove compatibility layer, as soon as all groupcard widgets use the getGroup feature

        if (this.props.onGroupOpened && this.props.getGroup) {
            const group = await this.props.getGroup(id);
            const groupId = id === 'own' ? group.id : id;
            this.props.onGroupOpened(groupId, group.items, title);
        }


        if (!this.props.getGroup) {
            this.setState({
                selectedGroup: id,
                selectedGroupTitle: title
            });

            return;
        }

        let g;
        if (!this.state.fetchedGroups[id]) {
            g = await this.props.getGroup(
                id, id => this.showView('detailView', id)
            );
        }

        this.setState(s => {
            if (g) s.fetchedGroups[id] = g;

            return {
                selectedGroup: id,
                selectedGroupTitle: title,
                fetchedGroups: s.fetchedGroups
            }
        });
    }

    toggleMode() {
        this.setState(s => ({
            mode: s.mode && (s.mode === 'list' ? 'items' : 'list')
        }))
    }

    render() {
        const DetailView = this.props.detailView;
        const CreateView = this.props.createView;
        const GroupView = this.props.groupView;
        const SecondaryDetailView = this.props.secondaryDetailView;
        const {groupName, itemName} = this.props;

        if (DetailView && this.state.detailView) {
            return (
                <DetailView
                    id={this.state.detailView}
                    goBack={this.onGoBack}
                    data={this.state.detailView_data}
                    groupId={this.state.selectedGroup}
                />
            );
        }

        if (SecondaryDetailView && this.state.secondaryDetailView) {
            return (
                <SecondaryDetailView
                    group={this.state.secondaryDetailView}
                    goBack={this.onGoBack}
                />
            );
        }

        if (CreateView && this.state.createView) {
            return (
                <CreateView
                    groupId={this.state.createView}
                    goBack={this.onGoBack}
                    getGroups={this.props.getGroups}
                    getAllItems={this.props.getAllItems}
                    // items={_.find(this.state.groups,{id:this.state.createView}).items}
                />
            );
        }

        if (GroupView && this.state.groupView) {
            const g = _.find(this.state.groups, { id: this.state.groupView });
            return (
                <GroupView id={this.state.groupView} goBack={this.onGoBack}
                    group={g}
                />
            );
        }

        const groups = this.state.groups;
        if (!groups) return null;

        return (
            <GroupCards
                groupName={groupName}
                itemName={itemName}
                groups={groups}
                fetchedGroups={this.state.fetchedGroups}
                changeGroupTitle={this.changeGroupTitle}
                openGroup={this.openGroup}
                selectedGroup={this.state.selectedGroup}
                selectedGroupTitle={this.state.selectedGroupTitle}
                groupCardtitleInfo={this.props.groupCardtitleInfo}
                showGroupView={
                    GroupView ? id => this.showView('groupView', id) : null
                }
                deleteGroup={this.deleteGroup}
                addGroup={(this.props.createGroup || this.props.reduxCreateGroup) 
                    ? this.addGroup : null}
                addItem={this.props.createView ? groupId => this.showView('createView', groupId) : undefined}
                searchTerm={this.state.searchTerm}
                search={this.props.getGroupSearch ? this.search : null}
                useGravatar={this.props.useGravatar}
                uneditable={this.props.uneditable}
                editItemIcon={this.props.editItemIcon}
                onDrop={this.props.onDrop}

                //showDetailView={id => this.showView('detailView', id)}
                getGroup={this.props.getGroup}
                getItem={this.props.getItem}

                // to get back from secondary detail view
                // currently only used for channelgroups in channelgroups
                // and needed because it's just another channelGroupCards component
                secondaryGoBack={this.props.secondaryGoBack}

                // listview for some special cases like usermanager
                mode={this.state.mode}
                toggleMode={this.toggleMode}
                listColumns={this.props.listColumns}
                customTableRenderers={this.props.customTableRenderers}
                disabledColumnFilters={this.props.disabledColumnFilters}
            />
        );
    }
}

const mapStateToProps = (state) => ({ matomo: state.matomo });

export default connect(mapStateToProps, {})(withTranslation()(GroupCardsContainer));