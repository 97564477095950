import React, { useRef, useState } from 'react';
import styles from './ClusterHeader.module.scss';
import useDragHorizontal from '../../hooks/useDragHorizontal';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCopy, faRss, faUndo } from '@fortawesome/free-solid-svg-icons';
import { dateToString, downloadFile } from 'src/Widgets/common/helpers';
import APIReporting from 'src/API/APIReporting';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { useHistory } from 'react-router-dom';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';

const ClusterHeader = ({
    profileGroupContent,
    setActiveFilter,
    activeFilter,
    handleProfileFilter,
    selectedDateRange,
    isSearch,
    tooltipVisible,
    setTooltipVisible,
    setBackFillSelectionVisible,
    backFillSelectionVisible,
    selectedChannelGroup
}: MentionsVisualizerTypes.ClusterHeaderProps) => {
    const profileTitlesRef = useRef<HTMLDivElement>(null);
    const profileTitleSet = new Set();

    const history = useHistory();

    const { handleMouseDown } = useDragHorizontal({ itemsRef: profileTitlesRef });
    const { t } = useTranslation();

    const [downloadInProgress, setDownloadInProgress] = useState({
        xlsx: false,
        rss: false
    });

    //toggle folding of duplicates
    const toggleFolding = (isChecked: boolean) => {
        setActiveFilter((prev: any) => ({ ...prev, foldEnabled: isChecked }));
    };

    //handle RSS button click
    const handleRSSButtonClick = () => {
        setDownloadInProgress((prevState) => ({ ...prevState, rss: true }));
        const uniqueProfileIds = [
            ...new Set(
                profileGroupContent.clips.map(
                    (clip: { profileId: any }) => clip.profileId
                )
            )
        ].join(',');
        history.push(
            `/embed/rssFeed/${uniqueProfileIds}/${dateToString(
                selectedDateRange.startDate
            )}/${dateToString(selectedDateRange.endDate)}`
        );
        setDownloadInProgress((prevState) => ({ ...prevState, rss: false }));
    };

    //find unique profile titles
    const uniqueProfileTitles = profileGroupContent.clips.filter(
        (clip: { profileTitle: unknown }) => {
            const isNewProfileTitle = !profileTitleSet.has(clip.profileTitle);
            profileTitleSet.add(clip.profileTitle);
            return isNewProfileTitle;
        }
    );

    const handleChartIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setTooltipVisible(!tooltipVisible);
    };

    return (
        <>
            <h2 className={styles.profileGroupTitle}>{profileGroupContent.title}</h2>{' '}
            <h4 style={{
                margin: '5px'
            }}>{selectedChannelGroup ? `${t('Channel Group')}: ${selectedChannelGroup}` : null}</h4>
            {uniqueProfileTitles.length > 0 && (
                <div className={styles.profileTitlesContainer}>
                    <div
                        className={styles.profileTitles}
                        ref={profileTitlesRef}
                        onMouseDown={handleMouseDown}
                        style={
                            uniqueProfileTitles.length > 1
                                ? {
                                      cursor: 'grab',
                                      borderRight: '2px solid rgb(0, 56, 85)'
                                  }
                                : { cursor: 'default' }
                        }
                    >
                        {uniqueProfileTitles.map(
                            (clip: {
                                profileId: any;
                                notifID: React.Key | null | undefined;
                                profileTitle: string;
                            }) => {
                                const isActive = clip.profileId === activeFilter.profile;
                                return (
                                    <Button
                                        key={clip.notifID}
                                        type={isActive ? 'secondary' : 'primary'}
                                        data-testid={clip.profileTitle}
                                        btnClass={styles.profileTitleButton}
                                        onClick={() =>
                                            handleProfileFilter(clip.profileId)
                                        }
                                    >
                                        <span
                                            title={
                                                clip.profileTitle +
                                                ' (' +
                                                profileGroupContent.clips.filter(
                                                    (c: { profileId: any }) =>
                                                        c.profileId === clip.profileId
                                                ).length +
                                                ')'
                                            }
                                            className={styles.profileTitleCount}
                                        >
                                            {clip.profileTitle?.length > 20
                                                ? clip.profileTitle.substring(0, 20) +
                                                  '...'
                                                : clip.profileTitle}{' '}
                                            (
                                            {
                                                profileGroupContent.clips.filter(
                                                    (c: { profileId: any }) =>
                                                        c.profileId === clip.profileId
                                                ).length
                                            }
                                            )
                                        </span>
                                    </Button>
                                );
                            }
                        )}
                    </div>
                    <div className={styles.downloadIcons}>
                        <div
                            title={
                                activeFilter.foldEnabled
                                    ? t('Show All Clips')
                                    : t('Fold Duplicates')
                            }
                            style={
                                activeFilter.foldEnabled
                                    ? { color: '#c4a01b' }
                                    : { color: '#23527c' }
                            }
                            className={styles.foldDuplicatesIcon}
                            onClick={() => toggleFolding(!activeFilter.foldEnabled)}
                        >
                            <span
                                style={{
                                    fontSize: '1rem',
                                    minWidth: '9rem',
                                    display: 'flex',
                                    gap: '0.2rem',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                                {activeFilter.foldEnabled
                                    ? 'Show All Clips'
                                    : 'Fold Duplicates'}
                            </span>
                        </div>
                        {'  | '}
                        {
                            <div
                                className={styles.chartIcon}
                                onClick={handleChartIconClick}
                            >
                                <FontAwesomeIcon icon={faChartLine} />
                            </div>
                        }
                        {isSearch && (
                            <div
                                className={styles.backFillIcon}
                                onClick={() =>
                                    setBackFillSelectionVisible(!backFillSelectionVisible)
                                }
                            >
                                <FontAwesomeIcon icon={faUndo} />
                            </div>
                        )}
                        <div
                            className={styles.xlsxDownloadIcon}
                            onClick={async () => {
                                setDownloadInProgress((prevState) => ({
                                    ...prevState,
                                    xlsx: true
                                }));
                                const uniqueProfileIds = [
                                    ...new Set(
                                        profileGroupContent.clips.map(
                                            (clip: { profileId: string | number }) =>
                                                isSearch
                                                    ? clip.profileId + 't'
                                                    : clip.profileId
                                        )
                                    )
                                ];
                                const xlsx = await APIReporting.getAllHitsXlsx(
                                    uniqueProfileIds,
                                    {
                                        from: dateToString(selectedDateRange.startDate),
                                        to: dateToString(selectedDateRange.endDate)
                                    }
                                );
                                downloadFile(
                                    xlsx,
                                    `eMM_profile-hits_${profileGroupContent.title}.xlsx`,
                                    'xlsx'
                                );
                                downloadFile(
                                    xlsx,
                                    `eMM_profile-hits_${profileGroupContent.title}.xlsx`,
                                    'xlsx'
                                );
                                setDownloadInProgress((prevState) => ({
                                    ...prevState,
                                    xlsx: false
                                }));
                            }}
                        >
                            {downloadInProgress.xlsx ? (
                                <LoadingSpinner size="1.2rem" />
                            ) : (
                                <img src={'/mms/images/xlsx.png'} alt="xlsx" width={21} />
                            )}
                        </div>
                        {!isSearch && (
                            <div
                                onClick={handleRSSButtonClick}
                                className={styles.rssDownloadIcon}
                            >
                                {downloadInProgress.rss ? (
                                    <LoadingSpinner size="1.2rem" />
                                ) : (
                                    <FontAwesomeIcon icon={faRss} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ClusterHeader;
