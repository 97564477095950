import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { EPGRowProps } from './EPGRow.types';
import styles from '../EPGContent/EPGContent.module.scss'


const EPGRow: FC<EPGRowProps> = ({
    genre,
    titleProp,
    onUnfold,
    description,
    onClick,
    date,
    start,
    end,
    isOpen,
    availability
}) => {
    const { t } = useTranslation();
    const icon = isOpen ? faChevronUp : faChevronDown;

    let title;
    switch (availability?.statusCode) {
        case 'P':
            title = t('goto_content_available_partial');
            break;
        case 'N':
            title = t('goto_content_not_available');
            break;
        default:
            title = t('Click to view media');
            break;
    }

    let combinedClassName = `${styles.epg}`;

    if (availability?.status === 'available') {
        combinedClassName += ` ${styles.epgAvailable}`;
      } else if (availability?.status === 'partially-available') {
        combinedClassName += ` ${styles.epgPartiallyAvailable}`;
      } else {
        combinedClassName += ` ${styles.epgUnavailable}`;
      }

    return (
        <div className={`${styles.epgRow} ${combinedClassName}`}>
            <div className={styles.epgNoshrink}>{date}</div>
            <div className={styles.epgNoshrink}>{start}</div>
            <div className={styles.epgNoshrink}>{end}</div>
            <div className={styles.epgRowTitleCard}>
                <div className={styles.epgRowTitle}>
                    <button
                        className={styles.epgRowClickableTitle}
                        style={{
                            cursor: onClick ? 'pointer' : 'default'
                        }}
                        onClick={onClick}
                        title={title}
                    >
                        {titleProp}
                    </button>
                    <div>
                        {genre ? <span className={styles.epgGenre}>{genre}</span> : null}
                        <FontAwesomeIcon
                            icon={icon}
                            onClick={onUnfold}
                            className={styles.epgUnfoldIcon}
                        />
                    </div>
                </div>
                {isOpen && (
                    <div className={`${styles.epgDescription} ${'animated fadeIn'}`}>{description}</div>
                )}
            </div>
        </div>
    );
};

export default EPGRow


/* class EPGRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const props = this.props;
        const {t} = this.props;
        const icon = props.isOpen ? 'chevron-up' : 'chevron-down';

        let title;
        switch(props.availability.statusCode){
            case 'P':
                title = t('goto_content_available_partial');
                break;
            case 'N':
                title = t("goto_content_not_available");
                break;
            default:
                title = t("Click to view media");
                break;
        }

        return (
            <div className={'epg-row epg-' + props.availability.status}>
                <div className="epg-noshrink">{props.date}</div>
                <div className="epg-noshrink">{props.start}</div>
                <div className="epg-noshrink">{props.end}</div>
                <div className="epg-row-title-card">
                    <div className="epg-row-title">
                        <button
                            className="epg-row-clickable-title"
                            style={{
                                cursor: props.onClick ? 'pointer' : 'default',
                            }}
                            onClick={props.onClick}
                            title={title}
                        >
                            {props.title}
                        </button>
                        <div>
                            {props.genre ? <span className="epg-genre">{props.genre}</span> : null}
                            <FontAwesomeIcon
                                icon={icon}
                                onClick={props.onUnfold}
                                className="epg-unfold-icon"
                            />
                        </div>
                    </div>
                    {props.isOpen && (
                        <div className="epg-description animated fadeIn">
                            {props.description}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default withTranslation()(EPGRow); */